import { ICompanyState, IDbUserState, IInitialUserState } from "interfaces/user.interface"

const initialUserState: IInitialUserState = {
	dbUser: {} as IDbUserState,
	company: {} as ICompanyState,
	isLoading: false,
	login: () => { },
	updateUser: () => { },
	updateUserAvatar: () => { },
	updateCompanyLogo: () => { },
	updateCompanyData: () => { },
	updateLanguage: () => { },
	updateSocialMedia: () => { },
	removeSocialMedia: () => { },
	selectUserSuperAdmin: () => { },
	selectCompanySuperAdmin: () => { }
}

export default initialUserState