export interface IinitialBrandFeedState {
	brand: {
		info: {}
		banner: {}
		products: []
		videos: []
	}
	getBrandFeed: (tenantId: string) => Promise<boolean>
}

const initialBrandFeedState: IinitialBrandFeedState = {
	brand: {
		info: {},
		banner: {},
		products: [],
		videos: []
	},
	getBrandFeed: () => Promise.resolve(false),
}

export default initialBrandFeedState