import { basePath } from "./utils/config"

export const getCompanyInfoAPI = async (clientId: string, token: string): Promise<any> => {
	const params = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	}
	const response = await fetch(`${basePath}/client_profile/get_client_profile?client_id=${clientId}`, params)
	const data = await response.json()
	return data
}

export const getBrandContactsAPI = async (tenantId: string, token: string): Promise<any> => {
	const params = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	}
	const response = await fetch(`${basePath}/brand_profile/get_brand_contacts?tenant_id=${tenantId}`, params)
	const data = await response.json()
	return { response, data }
}

export const updateCompanyDataAPI = async (body: { filter: { id: string }; update: any }, token: string): Promise<any> => {
	const params = {
		method: "PATCH",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body),
	}
	const response = await fetch(`${basePath}/client_profile/update_client_profile`, params)
	const data = await response.json()
	return { response, data }
}

export const getTenantSocialMediaAPI = async (clientId: string, token: string): Promise<any> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			tenant_id: clientId
		}),
	}
	const response = await fetch(`${basePath}/social_media/get_social_media`, params)
	const data = await response.json()
	return { response, data }
}

export const addSocialMediaAPI = async (clientId: string, items: any, token: string): Promise<any> => {
	const params = {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			client_id: clientId,
			items: items
		}),
	}
	const response = await fetch(`${basePath}/social_media/add_social_media`, params)
	const data = await response.json()
	return { response, data }
}

export const removeSocialMediaAPI = async (socialMediaId: string, token: string): Promise<any> => {
	const params = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			target: "client",
			ids: [socialMediaId]
		}),
	}
	const response = await fetch(`${basePath}/social_media/delete_social_media`, params)
	const data = await response.json()
	return { response, data }
}

export const updateSocialMediaAPI = async (client_id: string, id: string, url: string, name: string, token: string): Promise<any> => {
	const params = {
		method: "PATCH",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			client_id: client_id,
			id: id,
			url: url,
			name: name
		}),
	}
	const response = await fetch(`${basePath}/social_media/update_social_media`, params)
	const data = await response.json()
	return { response, data }
}