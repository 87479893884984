import { IBodyProductSearch, IProductFetchDataResponse } from "interfaces/catalog.interface"
import { basePath } from "./utils/config"

export const getCatalog = async (body: IBodyProductSearch, token: string, signal: AbortSignal | undefined): Promise<any> => {
	const params = {
		signal: signal,
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body),
	}
	const response = await fetch(`${basePath}/product/brand/get_product`, params)
	const data = await response.json()
	return { response, data }
}

// export const getFiltersAPII = async (brandId: string, clientId: string, countryId: string, languageId: string, token: string): Promise<any> => {
// 	const params = {
// 		method: "GET",
// 		headers: {
// 			Authorization: `Bearer ${token}`,
// 			"Content-Type": "application/json",
// 		},
// 	}
// 	const response = await fetch(`${basePath}/filter/get_client_filter?brand_id=${brandId}&client_id=${clientId}&country_id=${countryId}&language_id=${languageId}`, params)
// 	const data = await response.json()
// 	return { response, data }
// }

export const getFiltersAPI = async (brandsId: string[], clientId: string, countryId: string, languageId: string, token: string): Promise<any> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			client_id: clientId,
			brand_id: brandsId,
			language_id: languageId,
			country_id: countryId
		}),
	}
	const response = await fetch(`${basePath}/filter/get_client_filter`, params)
	const data = await response.json()
	return { response, data }
}

export const getTenantsAPI = async (clientId: string, countryId: string, token: string): Promise<any> => {
	const params = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	}
	const response = await fetch(`${basePath}/association/get_client_associations?client_id=${clientId}&country_id=${countryId}`, params)
	const data = await response.json()
	return { response, data }
}

export const addLikeAPI = async (userId: string, productId: string, token: string): Promise<any> => {
	const params = {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			user_id: userId,
			product_id: productId
		}),
	}
	const response = await fetch(`${basePath}/favorite/create_favorite_product`, params)
	const data = await response.json()
	return { response, data }
}

export const removeLikeAPI = async (favoriteId: string, token: string): Promise<any> => {
	const params = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		}
	}
	const response = await fetch(`${basePath}/favorite/delete_favorite_product?favorite_id=${favoriteId}`, params)
	const data = await response.json()
	return { response, data }
}

type TgetProductAPIResponse = {
	response: Response
	data: IProductFetchDataResponse
}
export const getProductAPI = async (productId: string, brandId: string, languageId: string, userId: string, token: string): Promise<TgetProductAPIResponse> => {
	const params = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	}
	const response = await fetch(`${basePath}/product/product_profile?product_id=${productId}&brand_id=${brandId}&language_id=${languageId}&user_id=${userId}`, params)
	const data = await response.json()
	return { response, data }
}