import { IMyBrand, IinitialMyBrandsState } from "interfaces/myBrands.interface"

const initialMyBrandsState: IinitialMyBrandsState = {
	myBrands: [] as IMyBrand[],
	connectedBrands: [] as IMyBrand[],
	brandSelected: {} as IMyBrand,
	pointsOfSaleConnected: [],
	isLoading: true,
	getMyBrands: () => { },
	removeFromMyBrand: () => { },
	removeFromMyBrandsMultiple: () => Promise.resolve(false),
	addToMyBrand: () => { },
	isLoadingAddToMyBrand: {
		isLoading: false,
		brandId: ''
	},
	createNewBrand: () => { },
	// Add to my brand multile
	atmbm: {
		haveBrandsToAdd: true,
		isLoading: false,
		totalBrands: 0,
		brandsToAdd: 0,
		brandsAdded: 0,
		isOpenModal: false,
	},
	addToMyBrandMultiple: () => Promise.resolve()
}

export default initialMyBrandsState