import { ICreateMappingBody } from "interfaces/sales.interface"
import { basePath } from "./utils/config"
import { IdeleteMappingAPIResponse, IdeleteSaleAPIResponse, IGetMyMappingsResponse, IgetPointsOfSaleMatchingAPIResponse, IgetSalesHistoryAPIResponse, IGetSalesMappingKeysAPIResponse, IsaveSaleFileAPIResponse, IupdatePointsOfSaleMapAPIResponse, TCreateMappingResponse, TgetPointsOfSaleMatchingAPIProps, TsaveSaleFileAPIProps, TUpdatePointsOfSaleMapAPIProps } from "./responseInterfaces/sales.response.interface"

export const getSalesHistoryAPI = async (clientId: string, token: string): Promise<IgetSalesHistoryAPIResponse> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			client_id: clientId,
			index: 0,
			limit: 30000
		}),
	}
	const response = await fetch(`${basePath}/upload_control/sales`, params)
	const data = await response.json()
	return { response, data }
}

export const deleteSaleAPI = async (uploadId: string, token: string): Promise<IdeleteSaleAPIResponse> => {
	const params = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	}
	const response = await fetch(`${basePath}/upload_control/delete_sale?id=${uploadId}`, params)
	const data = await response.json()
	return { response, data }
}

export const getSalesMappingKeysAPI = async (token: string): Promise<IGetSalesMappingKeysAPIResponse> => {
	const params = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	}
	const response = await fetch(`${basePath}/csv_mapping/get_sales_keys`, params)
	const data = await response.json()
	return { response, data }
}

export const getMyMappingsAPI = async (clientId: string, category: 'sale' | 'catalog', token: string): Promise<IGetMyMappingsResponse> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			client_id: clientId,
			category: category,
		}),
	}
	const response = await fetch(`${basePath}/csv_mapping/get_maps`, params)
	const data = await response.json()
	return { response, data }
}

export const deleteMappingAPI = async (mappingId: string, token: string): Promise<IdeleteMappingAPIResponse> => {
	const params = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	}
	const response = await fetch(`${basePath}/csv_mapping/delete_map?id=${mappingId}`, params)
	const data = await response.json()
	return { response, data }
}

export const createMappingAPI = async (body: ICreateMappingBody, token: string): Promise<TCreateMappingResponse> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body),
	}
	const response = await fetch(`${basePath}/csv_mapping/map_csv`, params)
	const data = await response.json()
	return { response, data }
}

export const getPointsOfSaleMatchingAPI = async ({ companyId, pointsOfSaleNames, csvMapId, token }: TgetPointsOfSaleMatchingAPIProps): Promise<IgetPointsOfSaleMatchingAPIResponse> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			client_id: companyId,
			csv_map_id: csvMapId,
			point_of_sale: pointsOfSaleNames
		}),
	}
	const response = await fetch(`${basePath}/point_of_sale_profile/match_point_of_sale`, params)
	const data = await response.json()
	return { response, data }
}

export const updatePointsOfSaleMapAPI = async ({ csvMapId, pointsOfSaleMap, token }: TUpdatePointsOfSaleMapAPIProps): Promise<IupdatePointsOfSaleMapAPIResponse> => {
	const params = {
		method: "PATCH",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			csv_map_id: csvMapId,
			point_of_sale_map: pointsOfSaleMap
		}),
	}
	const response = await fetch(`${basePath}/csv_mapping/update_point_of_sale_map`, params)
	const data = await response.json()
	return { response, data }
}

export const saveSaleFileAPI = async ({ csvMapId, dataJSON, token, original_content_type, original_name, encoding }: TsaveSaleFileAPIProps): Promise<IsaveSaleFileAPIResponse> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			csv_map_id: csvMapId,
			original_content_type,
			original_name,
			encoding,
			origin: "platform",
			data: dataJSON
		}),
	}
	const response = await fetch(`${basePath}/upload/upload_sales_csv`, params)
	const data = await response.json()
	return { response, data }
}

type TrunSalesEtlAPIResponse = {
	response: Response
	data: {
		info: "executing etl task"
	}
}
export const runSalesEtlAPI = async (token: string): Promise<TrunSalesEtlAPIResponse> => {
	const params = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	}
	const response = await fetch(`${basePath}/sftp/run_sftp_task`, params)
	const data = await response.json()
	return { response, data }
}