import { useReducer, useMemo, useCallback, memo } from 'react'
import BrandFeedContext from './BrandFeedContext'
import initialBrandFeedState, { IinitialBrandFeedState } from './initialBrandFeedState'
import brandFeedReducer from './reducer/brandFeed.reducer'
import * as action from "./reducer/brandFeed.actions";
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { ChildrenProps } from 'interfaces/general.interface';

function BrandFeedProvider(props: ChildrenProps) {
	const [saState, dispatch] = useReducer(brandFeedReducer, initialBrandFeedState)
	const brandFeedState = saState as IinitialBrandFeedState
	const { getAccessTokenSilently } = useAuth0()
	const { t: translate } = useTranslation()

	const getBrandFeed = useCallback(async (tenantId: string) => {
		const token = await getAccessTokenSilently()
		return await action.getBrandFeedAction(dispatch, tenantId, token, translate) as boolean
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [translate])

	const memoProvider = useMemo(
		() => ({
			...brandFeedState,
			getBrandFeed
		}), [
		brandFeedState,
		getBrandFeed
	]
	);

	return (
		<BrandFeedContext.Provider value={memoProvider}>
			{props.children}
		</BrandFeedContext.Provider>
	)
}

export default memo(BrandFeedProvider)