import { IBodyMultimediaFetch, MultimediaItemState, MultimediaTypes, TBrandSelected, TInitialMultimediaState } from "interfaces/multimedia.interface"
import { bannerType, imageType, videoType } from "views/pages/main/multimediaPage/components/constants"

export const mediaDefaultLimit = 15

const bodyMultimediaImagesFetch: IBodyMultimediaFetch = {
	options: {
		brand_id: [],
		multimedia_type: [imageType],
		search: [],
		limit: mediaDefaultLimit,
		index: 0
	},
	filter: {
		tag: [],
		product: [],
		division: [],
		gender: [],
		country: [],
		season: []
	}
}
const bodyMultimediaBannersFetch: IBodyMultimediaFetch = {
	options: {
		brand_id: [],
		multimedia_type: [bannerType],
		search: [],
		limit: mediaDefaultLimit,
		index: 0
	},
	filter: {
		tag: [],
		product: [],
		division: [],
		gender: [],
		country: [],
		season: []
	}
}
const bodyMultimediaVideosFetch: IBodyMultimediaFetch = {
	options: {
		brand_id: [],
		multimedia_type: [videoType],
		search: [],
		limit: mediaDefaultLimit,
		index: 0
	},
	filter: {
		tag: [],
		product: [],
		division: [],
		gender: [],
		country: [],
		season: []
	}
}

const initialMultimediaState: TInitialMultimediaState = {
	image: {
		items: [] as MultimediaItemState[],
		itemSelected: {} as MultimediaItemState,
		bodyMultimediaFetch: bodyMultimediaImagesFetch,
		isLoading: true,
		isLoadingMoreMedia: false,
		thereAreMoreMedia: true,
		search: {
			media: [] as MultimediaItemState[],
			query: '',
		},
		brandSelected: {} as TBrandSelected
	},
	video: {
		items: [] as MultimediaItemState[],
		itemSelected: {} as MultimediaItemState,
		bodyMultimediaFetch: bodyMultimediaVideosFetch,
		isLoading: true,
		isLoadingMoreMedia: false,
		thereAreMoreMedia: true,
		search: {
			media: [] as MultimediaItemState[],
			query: '',
		},
		brandSelected: {} as TBrandSelected
	},
	banner: {
		items: [] as MultimediaItemState[],
		itemSelected: {} as MultimediaItemState,
		bodyMultimediaFetch: bodyMultimediaBannersFetch,
		isLoading: true,
		isLoadingMoreMedia: false,
		thereAreMoreMedia: true,
		search: {
			media: [] as MultimediaItemState[],
			query: '',
		},
		brandSelected: {} as TBrandSelected
	},
	videosByBrand: [] as MultimediaItemState[],
	getMedia: (type: MultimediaTypes) => { },
	getMoreMedia: (type: MultimediaTypes) => { },
	setBrandSelected: (brand: any, type: MultimediaTypes) => { },
	getVideosByBrand: (brandId: string) => { },
	searchMedia: (querySearch: string, type: MultimediaTypes) => { },
}

export default initialMultimediaState