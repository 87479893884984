import { ICatalogState, TQuerySearchArray } from 'interfaces/catalog.interface';
import * as CatalogTypes from './catalog.types'

export default function catalogReducer(state: ICatalogState, action: any) {
	const { type, payload } = action;

	switch (type) {
		case CatalogTypes.GET_CATALOG:
			return {
				...state,
				catalogItems: payload
			}

		case CatalogTypes.SET_RELATED_PRODUCTS:
			return {
				...state,
				relatedProducts: payload
			}

		case CatalogTypes.ADD_MORE_TO_CATALOG:
			return {
				...state,
				catalogItems: {
					...state.catalogItems,
					data: state.catalogItems.data.concat(payload.data)
				}
			}

		case CatalogTypes.GET_FILTER:
			return {
				...state,
				filteredItems: payload
			}

		case CatalogTypes.GET_COLLECTION:
			return {
				...state,
				collectionItems: payload
			}

		case CatalogTypes.GER_PRODUCT:
			return {
				...state,
				productItem: payload
			}

		case CatalogTypes.SET_IS_LOADING:
			return {
				...state,
				isLoading: payload
			}

		case CatalogTypes.SET_IS_LOADING_MORE_PRODUCTS:
			return {
				...state,
				isLoadingMoreProducts: payload
			}

		case CatalogTypes.SET_IS_LOADING_RELATED_PRODUCTS:
			return {
				...state,
				isLoadingRelatedProducts: payload
			}

		case CatalogTypes.RESET_PRICE_SLIDER:
			return {
				...state,
				bodyProductSearch: {
					...state.bodyProductSearch,
					filter: {
						...state.bodyProductSearch.filter,
						[payload.type]: []
					}
				},
				bodyProductSearchTemp: {
					...state.bodyProductSearchTemp,
					filter: {
						...state.bodyProductSearchTemp.filter,
						[payload.type]: []
					}
				},
			}

		case CatalogTypes.RESET_TEMP_BODY_FILTERS:
			return {
				...state,
				bodyProductSearchTemp: state.bodyProductSearch,
				filtersLengthsTemp: state.filtersLengths
			}

		case CatalogTypes.SET_BODY_PRODUCT:
			return {
				...state,
				bodyProductSearchTemp: {
					...state.bodyProductSearchTemp,
					options: {
						...state.bodyProductSearchTemp.options,
						brand_id: payload.brandId,
						client_id: payload.clientId,
						limit: payload.limit,
						language_id: payload.languageId,
					},
					filter: {
						reference: [],
						color: [],
						season: [],
						segmentation: [],
						division: [],
						family: [],
						gender: [],
						pvi: [],
						pvpr: [],
						tag: [],
						tier: [],
					}
				},
				querysearchArray: [],
				filtersLengths: {
					brand: payload.brandId.length,
					color: 0,
					gender: 0,
					division: 0,
					family: 0,
					season: 0
				},
			}

		case CatalogTypes.SET_BODY_PRODUCT_SEARCH:
			return {
				...state,
				bodyProductSearch: payload,
				filtersLengths: {
					brand: payload.options.brand_id.length,
					color: state.filtersLengthsTemp.color,
					gender: state.filtersLengthsTemp.gender,
					division: state.filtersLengthsTemp.division,
					family: state.filtersLengthsTemp.family,
					season: state.filtersLengthsTemp.season
				}
			}

		case CatalogTypes.SET_BODY_PRODUCT_SEARCH_TEMP:
			return {
				...state,
				bodyProductSearchTemp: payload
			}

		case CatalogTypes.SET_QUERY_SEARCH:
			return {
				...state,
				querySearch: payload
			}


		case CatalogTypes.INCREMENT_PAGINATION_INDEX:
			return {
				...state,
				bodyProductSearchTemp: {
					...state.bodyProductSearchTemp,
					options: {
						...state.bodyProductSearchTemp.options,
						index: payload
					}
				}
			}

		case CatalogTypes.SET_PRICE_SLIDERS:
			return {
				...state,
				bodyProductSearchTemp: {
					...state.bodyProductSearchTemp,
					filter: {
						...state.bodyProductSearchTemp.filter,
						[payload.field]: payload.value
					}
				}
			}

		case CatalogTypes.ADD_BODY_FILTERS:
			return {
				...state,
				bodyProductSearchTemp: payload.bodyProductSearchTemp,
				filtersLengthsTemp: {
					...state.filtersLengthsTemp,
					[payload.field]: state.filtersLengthsTemp[payload.field as keyof typeof state.filtersLengthsTemp] + 1
				}
			}

		case CatalogTypes.REMOVE_BODY_FILTERS:
			return {
				...state,
				bodyProductSearchTemp: payload.bodyProductSearchTemp,
				filtersLengthsTemp: {
					...state.filtersLengthsTemp,
					[payload.field]: state.filtersLengthsTemp[payload.field as keyof typeof state.filtersLengthsTemp] - 1
				}
			}

		case CatalogTypes.ADD_BODY_BRAND_OPTIONS:
			return {
				...state,
				bodyProductSearchTemp: payload.bodyProductSearchTemp,
				filtersLengths: {
					...state.filtersLengths,
					brand: state.filtersLengths.brand + 1
				}
			}

		case CatalogTypes.REMOVE_BODY_BRAND_OPTIONS:
			return {
				...state,
				bodyProductSearchTemp: payload.bodyProductSearchTemp,
				filtersLengths: {
					...state.filtersLengths,
					brand: state.filtersLengths.brand - 1
				}
			}

		case CatalogTypes.RESET_BODY_FILTERS:
			return {
				...state,
				bodyProductSearch: {
					...state.bodyProductSearch,
					options: {
						...state.bodyProductSearch.options,
						favorite: "",
						// search: [""],
						index: 0
					},
					filter: {
						...state.bodyProductSearch.filter,
						color: [],
						season: [],
						segmentation: [],
						division: [],
						family: [],
						gender: [],
						pvi: [],
						pvpr: [],
						tag: [],
						tier: [],
					}
				},
				bodyProductSearchTemp: {
					...state.bodyProductSearchTemp,
					options: {
						...state.bodyProductSearchTemp.options,
						favorite: "",
						// search: [""],
						index: 0
					},
					filter: {
						...state.bodyProductSearchTemp.filter,
						color: [],
						season: [],
						segmentation: [],
						division: [],
						family: [],
						gender: [],
						pvi: [],
						pvpr: [],
						tag: [],
						tier: [],
					}
				},
				filtersLengths: {
					brand: 0,
					color: 0,
					gender: 0,
					division: 0,
					family: 0,
					season: 0
				},
				filtersLengthsTemp: {
					brand: 0,
					color: 0,
					gender: 0,
					division: 0,
					family: 0,
					season: 0
				},
				querysearchArray: []
			}

		case CatalogTypes.SET_THERE_ARE_MORE_PRODUCTS:
			return {
				...state,
				thereAreMoreProducts: payload
			}

		case CatalogTypes.SET_IS_EMPTY_PRODUCTS:
			return {
				...state,
				isEmptyProducts: payload
			}

		case CatalogTypes.SET_SORT_BY:
			return {
				...state,
				bodyProductSearchTemp: {
					...state.bodyProductSearchTemp,
					options: {
						...state.bodyProductSearchTemp.options,
						order: [payload],
					}
				}
			}

		case CatalogTypes.SET_BRANDS_PRODUCTS:
			return {
				...state,
				bodyProductSearchTemp: {
					...state.bodyProductSearchTemp,
					options: {
						...state.bodyProductSearchTemp.options,
						brand_id: payload,
					}
				}
			}

		case CatalogTypes.SET_BRAND_SELECTED:
			return {
				...state,
				brandSelected: payload
			}

		case CatalogTypes.SET_FAVORITES:
			return {
				...state,
				bodyProductSearchTemp: {
					...state.bodyProductSearchTemp,
					options: {
						...state.bodyProductSearchTemp.options,
						favorite: payload,
					}
				}
			}

		case CatalogTypes.SET_FILTERS_LENGTHS:
			return {
				...state,
				filtersLengthsTemp: payload
			}

		case CatalogTypes.SET_PRODUCT_BRAND_ID:
			return {
				...state,
				productItem: {
					...state.productItem,
					brand_id: payload
				}
			}

		case CatalogTypes.SET_IS_LOADING_PRODUCT:
			return {
				...state,
				isLoadingProduct: payload
			}

		case CatalogTypes.ADD_TO_QUERY_SEARCH_ARRAY:
			return {
				...state,
				querysearchArray: !state.querysearchArray.includes(payload) ? [...state.querysearchArray, payload] : state.querysearchArray
			}

		case CatalogTypes.REMOVE_FROM_QUERY_SEARCH_ARRAY:
			return {
				...state,
				querysearchArray: state.querysearchArray.filter((item) => item.tag !== payload.tag),
			}

		case CatalogTypes.SET_QUERY_SEARCH_ARRAY:
			return {
				...state,
				querysearchArray: payload,
			}

		case CatalogTypes.SET_BODY_SEARCH_FILTER:
			return {
				...state,
				querysearchArray: (payload.querySearchArray as TQuerySearchArray[]).filter((item) => item.tag !== payload.value.tag),
				filtersLengthsTemp: {
					...state.filtersLengthsTemp,
					[payload.field]: state.filtersLengthsTemp[payload.field as keyof typeof state.filtersLengthsTemp] - 1
				}
			}

		case CatalogTypes.ADD_LIKE_TO_PRODUCT:
			const newObj = {
				favorite_id: payload.id,
				user_id: payload.user_id
			}
			return {
				...state,
				productItem: {
					...state.productItem,
					favorite: [...state.productItem.favorite, newObj]
				}
			}

		case CatalogTypes.REMOVE_LIKE_TO_PRODUCT:
			return {
				...state,
				productItem: {
					...state.productItem,
					favorite: state.productItem.favorite.filter((item: any) => item.user_id !== payload)
				}
			}

		case CatalogTypes.RESET_CATALOG_ITEMS:
			return {
				...state,
				catalogItems: {
					count: 0,
					data: []
				},
				thereAreMoreProducts: false
			}

		default:
			return state
	}
}