import { IInitialMetaState } from "interfaces/meta.interface"

const initialMetaState: IInitialMetaState = {
	sftp: {
		sftpConnections: [],
		isLoadingSftpConnections: false,
		getSftpConnections: () => { },
		createSftpConnection: () => Promise.resolve(false),
		isLoadingCreateSftpConnection: false,
		removeSftpConnection: () => { },
		isLoadingRemoveSftpConnection: {
			loading: false,
			user_name: ""
		},
	}
}

export default initialMetaState