import * as api from 'api/brandUsers.api';
import * as BrandUsersTypes from './brandUsers.types'
import { brandUsersError } from 'utils/errorCodes';
import { notificationErr, notificationError } from 'views/components/UI/notification';
import { messageError, messageSuccess, messageWarning } from 'views/components/UI/message';
import { IBrandUser, IInitialBrandUsersState, IUserPointOfSale, TEditUserFormValues } from 'interfaces/brandUsers.interface';
import { superAdminRole } from 'utils/constants';

export async function getBrandUsersAction(dispatch: any, clientId: string, userRole: string, token: string, translate: any) {
	dispatch({ type: BrandUsersTypes.SET_IS_LOADING_USERS, payload: true })
	try {
		const brandusersFetch = await api.getBrandUsersAPI(clientId, token)
		if (brandusersFetch.response.status === 200) {
			const orderedBrandUsers: IBrandUser[] = []
			for (const [index, item] of brandusersFetch.data.entries()) {
				const userProfile = await api.getBrandUserProfileAPI(item.id, token)
				const userData = userProfile.data as IBrandUser
				if (userProfile.response.status === 200) {
					const obj: IBrandUser = {
						...userData,
						key: index,
						client_id: clientId,
						user_point_of_sales: (userData.user_point_of_sales as IUserPointOfSale[]).map((point) => point.point_of_sale_id),
					}
					orderedBrandUsers.push(obj)
				}
			}
			return dispatch({
				type: BrandUsersTypes.GET_BRAND_USERS,
				payload: userRole !== superAdminRole ? orderedBrandUsers.filter(item => item.username.split('@')[1] !== 'hub4retail.com') : orderedBrandUsers
			})
		}
	} catch (err) {
		messageError('Error al obtener los usuarios.')
		notificationError({
			msg: translate('notification-error_message'),
			description: translate('notification-error_description'),
			errorCode: brandUsersError.get_brand_users_001
		})
	} finally {
		dispatch({ type: BrandUsersTypes.SET_IS_LOADING_USERS, payload: false })
	}
}

export async function selectBrandUserAction(dispatch: any, user: IBrandUser | undefined, translate: any) {
	try {
		return dispatch({
			type: BrandUsersTypes.SELECT_BRAND_USER,
			payload: user ?? {}
		})
	} catch (err) {
		messageError('Error al seleccionar el usuario.')
		notificationError({
			msg: translate('notification-error_message'),
			description: translate('notification-error_description'),
			errorCode: brandUsersError.select_brand_user_001
		})
	}
}

export async function createBrandUserAction(dispatch: any, clientId: string, newBrandUser: any, languageId: string, token: string, setIsOpenModal: (isOpen: boolean) => void, translate: any, addUserClient: any) {
	dispatch({ type: BrandUsersTypes.SET_IS_LOADING_ACTION, payload: true })
	try {
		const body = {
			user_data: {
				email: newBrandUser.email,
				name: newBrandUser.name,
				nickname: newBrandUser.surname,
				verify_email: true,
				email_verified: false,
				blocked: false
			},
			user_metadata: {
				client_id: clientId,
				telephone_sufix: "",
				telephone_prefix: "",
				language: languageId,

				sector: newBrandUser.sector
			},
			role: newBrandUser.role
		}
		const createFetch = await api.createBrandUserAPI(body, token)
		if (createFetch.response.status === 409) {
			return messageWarning('El usuario ya existe.')
		}
		const { id, username, name, surname, tel_prefix, tel_sufix, profile_image, i18n_lang_id, role, sector, signed, first_login, lastLoging, enabled, createdAt, updatedAt } = createFetch.data.database.db_user
		const { user_id } = createFetch.data.auth0
		if (createFetch.response.status === 201 || createFetch.response.status === 200) {
			const userCreated = {
				key: id,
				id: id,
				client_id: clientId,
				auth_id: user_id,
				username: username,
				name: name,
				surname: surname,
				tel_prefix: tel_prefix,
				tel_sufix: tel_sufix,
				profile_image: profile_image,
				language: i18n_lang_id,
				role: role,
				sector: sector,
				signed: signed,
				first_login: first_login,
				last_login: lastLoging,
				enabled: enabled,
				createdAt: createdAt,
				updatedAt: updatedAt
			}
			await addUserClient(clientId, userCreated)
			dispatch({
				type: BrandUsersTypes.CREATE_BRAND_USER,
				payload: userCreated
			})
			messageSuccess('Usuario creado correctamente.')
			return setIsOpenModal(false)
		} else {
			messageError('Error al crear el usuario.')
			notificationError({
				msg: translate('notification-error_message'),
				description: translate('notification-error_description'),
				errorCode: brandUsersError.create_brand_user_002
			})
		}
	} catch (err) {
		messageError('Error al crear el usuario.')
		notificationError({
			msg: translate('notification-error_message'),
			description: translate('notification-error_description'),
			errorCode: brandUsersError.create_brand_user_001
		})
	} finally {
		dispatch({ type: BrandUsersTypes.SET_IS_LOADING_ACTION, payload: false })
	}
}

export async function updateBrandUserAction(dispatch: any, initialState: IInitialBrandUsersState, user: TEditUserFormValues, userId: string, updateRole: boolean, token: string, setIsOpenDrawer: (isOpen: boolean) => void, translate: any) {
	dispatch({ type: BrandUsersTypes.SET_IS_LOADING_ACTION, payload: true })
	if (updateRole) {
		const findUser = initialState.brandUsers.find((item) => item.id === userId)
		if (findUser) {
			if (findUser.role !== user.role) {
				try {
					// Update role
					const updateRoleFetch = await api.updateRoleAPI(userId, user.role, findUser.role, token)
					if (updateRoleFetch.response.status !== 200) {
						messageError('Error al actualizar el rol del usuario.')
						notificationErr(brandUsersError.update_brand_user_003, translate)
					}
				} catch (err) {
					messageError('Error al actualizar el rol del usuario.')
					notificationErr(brandUsersError.update_brand_user_004, translate)
				}
			}
		} else {
			messageError('Error al actualizar el rol del usuario.')
			notificationErr(brandUsersError.update_brand_user_005, translate)
		}
	}
	try {
		const body = {
			filter: { id: userId },
			update: {
				name: user.name,
				surname: user.surname,
				sector: user.sector,
			},
			point_of_sale: user.pointsOfSale
		}
		const updateFetch = await api.updateMainContactAPI(body, token)
		if (updateFetch.response.status === 200) {
			const findBrandUser = initialState.brandUsers.findIndex((item) => item.id === userId)
			const newBrandUser = [...initialState.brandUsers]
				; (newBrandUser[findBrandUser].name as string) = user.name
				; (newBrandUser[findBrandUser].surname as string) = user.surname
				; (newBrandUser[findBrandUser].sector as string) = user.sector
				; (newBrandUser[findBrandUser].user_point_of_sales as string[]) = user.pointsOfSale
			updateRole && ((newBrandUser[findBrandUser].role as string) = user.role)
			dispatch({
				type: BrandUsersTypes.UPDATE_BRAND_USER,
				payload: newBrandUser
			})
			messageSuccess('Usuario actualizado correctamente.')
			return setIsOpenDrawer(false)
		} else {
			messageError('Error al actualizar el usuario.')
			notificationErr(brandUsersError.update_brand_user_002, translate)
		}
	} catch (err) {
		messageError('Error al actualizar el usuario.')
		notificationErr(brandUsersError.update_brand_user_001, translate)
	} finally {
		dispatch({ type: BrandUsersTypes.SET_IS_LOADING_ACTION, payload: false })
	}
}

export async function deleteBrandUserAction(dispatch: any, userId: string, token: string, translate: any) {
	try {
		const updateFetch = await api.deleteBrandUserAPI(userId, token)
		if (updateFetch.response.status === 200) {
			dispatch({
				type: BrandUsersTypes.DELETE_BRAND_USER,
				payload: userId
			})
			messageSuccess('Usuario eliminado correctamente.')
		} else {
			messageError('Error al elimminar el usuario.')
			// notificationError({
			// 	msg: translate('notification-error_message'),
			// 	description: translate('notification-error_description'),
			// 	errorCode: brandUsersError.update_brand_user_002
			// })
		}
	} catch (err) {
		messageError('Error al eliminar el usuario.')
		// notificationError({
		// 	msg: translate('notification-error_message'),
		// 	description: translate('notification-error_description'),
		// 	errorCode: brandUsersError.update_brand_user_001
		// })
	}
}