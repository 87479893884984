import { IInitialBrandState } from 'interfaces/brand.interface';
import * as BrandTypes from './brand.types'

export default function brandReducer(state: IInitialBrandState, action: any) {
	const { type, payload } = action;

	switch (type) {
		case BrandTypes.GET_BRAND_INFO:
			return {
				...state,
				brand: { ...state.brand, info: payload }
			}

		case BrandTypes.SELECT_TENANT:
			return {
				...state,
				brand: { ...state.brand, tenantSelected: payload }
			}

		case BrandTypes.GET_BRAND_POINTS_OF_SALE:
			return {
				...state,
				brand: {
					...state.brand,
					tenantSelected: {
						...state.brand.tenantSelected,
						pointsOfSale: payload
					}
				}
			}

		case BrandTypes.SET_IS_LOADING:
			return {
				...state,
				isLoading: payload
			}

		default:
			return state
	}
}