import { createMappingAPI, deleteMappingAPI, getSalesHistoryAPI, getSalesMappingKeysAPI, getMyMappingsAPI, updatePointsOfSaleMapAPI, saveSaleFileAPI, deleteSaleAPI, runSalesEtlAPI } from 'api/sales.api';
import * as CatalogCsvUploadType from './sales.types'
import { ICreateMappingBody, ICSVMappingKey, IInitialSalesState } from 'interfaces/sales.interface';
import { messageError, messageSuccess } from 'views/components/UI/message';
import { notificationErr } from 'views/components/UI/notification';
import { salesError } from 'utils/errorCodes';
import { ICSVMappingItem, IMappingPointOfSale } from 'api/responseInterfaces/sales.response.interface';
import momenttz from 'moment-timezone';
import moment from 'moment';
import { ICompanyState } from 'interfaces/user.interface';
import { salesCreate, salesHistory } from 'router/paths';

export async function getSalesHistoryAction(dispatch: any, clientId: string, token: string, translate: any) {
  dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_HISTORY, payload: true })
  try {
    const getCatalogReportsByUpload = await getSalesHistoryAPI(clientId, token)
    const data = getCatalogReportsByUpload.data
    if (getCatalogReportsByUpload.response.status === 200 && data.length > 0) {
      const modifiedData = data.map((item: any, index) => {
        const { key, ...rest } = item
        return { value: key, key: index, ...rest }
      })
      dispatch({
        type: CatalogCsvUploadType.SET_SALES_HISTORY,
        payload: modifiedData
      })
    }
  } catch (error) {
    dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_HISTORY, payload: false })
    return notificationErr(salesError.get_sales_history_001, translate)
  } finally {
    dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_HISTORY, payload: false })
  }
}

export async function removeUploadAction(dispatch: any, uploadId: string, multiple: boolean, token: string, translate: any) {
  dispatch({
    type: CatalogCsvUploadType.SET_IS_LOADING_REJECT_UPLOAD,
    payload: { uploadId, isLoading: true }
  })
  try {
    const rejectUpload = await deleteSaleAPI(uploadId, token)
    if (rejectUpload.response.status === 200) {
      dispatch({
        type: CatalogCsvUploadType.SET_REMOVE_UPLOAD,
        payload: uploadId
      })
      if (multiple === false) {
        messageSuccess(translate('sales-csv-upload_reject-upload-success'))
      }
      return true
    } else {
      notificationErr(salesError.reject_upload_002, translate)
      messageError(translate('sales-csv-upload_reject-upload-error'))
      return false
    }
  } catch (error) {
    notificationErr(salesError.reject_upload_001, translate)
    messageError(translate('sales-csv-upload_reject-upload-error'))
    dispatch({
      type: CatalogCsvUploadType.SET_IS_LOADING_REJECT_UPLOAD,
      payload: { uploadId: '', isLoading: false }
    })
    return false
  } finally {
    dispatch({
      type: CatalogCsvUploadType.SET_IS_LOADING_REJECT_UPLOAD,
      payload: { uploadId: '', isLoading: false }
    })
  }
}

export async function setCSVSelectedAction(dispatch: any, csvData: Papa.ParseResult<unknown> | undefined, file: File, navigate: Function | undefined, sendFile: boolean) {
  dispatch({
    type: CatalogCsvUploadType.SET_CSV_SELECTED,
    payload: { papa: csvData, file: file }
  })
  dispatch({ type: CatalogCsvUploadType.SET_SEND_FILE, payload: sendFile })
  navigate && navigate(salesCreate)
  return dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_CSV_SELECTED, payload: false })
}

export async function setCSVSelectedCsvDataAction(dispatch: any, csvData: any[]) {
  dispatch({
    type: CatalogCsvUploadType.SET_CSV_SELECTED_CSV_DATA,
    payload: { csvData: csvData }
  })
}

export async function setCsvMappingKeysAction(dispatch: any, values: { [key: string]: string | undefined; }, csvMappingKeys: ICSVMappingKey[]) {
  // Set brand_ean, product_reference,color_code,product_size_eu optionals
  // if (values.brand_ean && csvMappingKeys.length > 0) {
  //   (csvMappingKeys.find(csv => csv.item === 'brand_ean') as ICSVMappingKey).required = true;
  //   (csvMappingKeys.find(csv => csv.item === 'product_reference') as ICSVMappingKey).required = false;
  //   (csvMappingKeys.find(csv => csv.item === 'color_code') as ICSVMappingKey).required = false;
  //   (csvMappingKeys.find(csv => csv.item === 'product_size_eu') as ICSVMappingKey).required = false;
  //   return dispatch({ type: CatalogCsvUploadType.SET_CSV_MAPPING_KEYS, payload: csvMappingKeys })
  // }
  // if (!values.brand_ean && (!values.product_reference || !values.color_code || !values.product_size_eu) && csvMappingKeys.length > 0) {
  //   (csvMappingKeys.find(csv => csv.item === 'brand_ean') as ICSVMappingKey).required = true;
  //   (csvMappingKeys.find(csv => csv.item === 'product_reference') as ICSVMappingKey).required = true;
  //   (csvMappingKeys.find(csv => csv.item === 'color_code') as ICSVMappingKey).required = true;
  //   (csvMappingKeys.find(csv => csv.item === 'product_size_eu') as ICSVMappingKey).required = true;
  //   return dispatch({ type: CatalogCsvUploadType.SET_CSV_MAPPING_KEYS, payload: csvMappingKeys })
  // }
  // if (values.product_reference && values.color_code && values.product_size_eu && csvMappingKeys.length > 0) {
  //   (csvMappingKeys.find(csv => csv.item === 'brand_ean') as ICSVMappingKey).required = false;
  //   (csvMappingKeys.find(csv => csv.item === 'product_reference') as ICSVMappingKey).required = true;
  //   (csvMappingKeys.find(csv => csv.item === 'color_code') as ICSVMappingKey).required = true;
  //   (csvMappingKeys.find(csv => csv.item === 'product_size_eu') as ICSVMappingKey).required = true;
  //   return dispatch({ type: CatalogCsvUploadType.SET_CSV_MAPPING_KEYS, payload: csvMappingKeys })
  // }
}

export async function getMappingsAction(dispatch: any, client: string, token: string, translate: any) {
  dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_CSV_MAPPINGS, payload: true })
  try {
    const [getMappingKeys, getMyMappings] = await Promise.all([
      getSalesMappingKeysAPI(token),
      getMyMappingsAPI(client, 'sale', token)
    ])

    if (getMappingKeys.response.status === 200) {
      const findIndexDateKey = getMappingKeys.data.findIndex((key) => key.item === 'date')
      if (findIndexDateKey) {
        getMappingKeys.data[findIndexDateKey].required = true
      }
      dispatch({
        type: CatalogCsvUploadType.SET_CSV_MAPPING_KEYS,
        payload: getMappingKeys.data
      })
    } else {
      notificationErr(salesError.get_mappings_002, translate)
      messageError(translate('sales-csv-upload_get-mappings-error-1'))
    }

    if (getMyMappings.response.status === 200 && getMyMappings.data.length > 0) {
      dispatch({
        type: CatalogCsvUploadType.SET_CSV_MAPPINGS,
        payload: getMyMappings.data
      })
    } else {
      // notificationErr(salesError.get_mappings_003, translate)
      // messageError(translate('sales-csv-upload_get-mappings-error-3'))
    }
  } catch (error) {
    notificationErr(salesError.get_mappings_001, translate)
    messageError(translate('sales-csv-upload_get-mappings-error-2'))
    dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_CSV_MAPPINGS, payload: false })
  } finally {
    dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_CSV_MAPPINGS, payload: false })
  }
}

export async function deleteMappingAction(dispatch: any, mappingId: string, token: string, translate: any) {
  dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_CSV_MAPPINGS, payload: true })
  try {
    const deleteMapping = await deleteMappingAPI(mappingId, token)
    if (deleteMapping.response.status === 200) {
      dispatch({
        type: CatalogCsvUploadType.DELETE_MAPPING,
        payload: mappingId
      })
      return messageSuccess(translate('sales-csv-upload_delete-mapping-success'))
    } else {
      return notificationErr(salesError.delete_mapping_002, translate)
    }
  } catch (error) {
    notificationErr(salesError.delete_mapping_001, translate)
    messageError(translate('sales-csv-upload_delete-mapping-error'))
    dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_CSV_MAPPINGS, payload: false })
  } finally {
    dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_CSV_MAPPINGS, payload: false })
  }
}

export async function createMappingAction(dispatch: any, body: ICreateMappingBody, sendFile: boolean, token: string, translate: any, navigate: Function) {
  dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_CSV_MAPPINGS, payload: true })
  try {
    const createMapping = await createMappingAPI(body, token)
    if (createMapping.response.status === 200) {
      dispatch({
        type: CatalogCsvUploadType.CREATE_MAPPING,
        payload: createMapping.data
      })
      if (sendFile === false) {
        setTimeout(async () => {
          try {
            const runEtl = await runSalesEtlAPI(token)
            if (runEtl.response.status === 200) {
              messageSuccess(translate('sales-csv-upload_create-mapping-success_without-send-file'), 7)
              return navigate(salesHistory)
            } else {
              return notificationErr(salesError.create_mapping_002, translate)
            }
          } catch (error) {
            return notificationErr(salesError.create_mapping_002, translate)
          }
        }, 2000)
      } else {
        return messageSuccess(translate('sales-csv-upload_create-mapping-success'))
      }
    } else {
      return notificationErr(salesError.create_mapping_002, translate)
    }
  } catch (error) {
    notificationErr(salesError.create_mapping_001, translate)
    messageError(translate('sales-csv-upload_create-mapping-error'))
    dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_CSV_MAPPINGS, payload: false })
  } finally {
    dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_CSV_MAPPINGS, payload: false })
  }
}

export async function updatePointsOfSaleMapAction(dispatch: any, csvMappings: ICSVMappingItem[], csvMapId: string, pointsOfSaleMap: { [key: string]: IMappingPointOfSale }, sendFile: boolean, token: string, translate: any) {
  try {
    const updatePSMap = await updatePointsOfSaleMapAPI({
      csvMapId,
      pointsOfSaleMap,
      token
    })
    if (updatePSMap.response.status === 200) {
      const findCsvMappingIndex = csvMappings.findIndex(csv => csv.id === csvMapId)
      if (findCsvMappingIndex === -1) {
        notificationErr(salesError.update_points_of_sale_map_003, translate)
        return false
      }
      csvMappings[findCsvMappingIndex].point_of_sale_map = pointsOfSaleMap
      dispatch({
        type: CatalogCsvUploadType.SET_CSV_MAPPINGS,
        payload: csvMappings
      })
      if (sendFile === false) {
        setTimeout(async () => {
          try {
            const runEtl = await runSalesEtlAPI(token)
            if (runEtl.response.status === 200) {
              messageSuccess(translate('sales-csv-upload_create-mapping-success_without-send-file'), 7)
              return true
            } else {
              return false
            }
          } catch (error) {
            return false
          }
        }, 2000)
      }
      return true
    } else {
      notificationErr(salesError.update_points_of_sale_map_002, translate)
      return false
    }
  } catch (error) {
    notificationErr(salesError.update_points_of_sale_map_001, translate)
  }
}

export async function saveSaleFileAction(dispatch: any, salesState: IInitialSalesState, csvMapId: string, company: ICompanyState, token: string, translate: any) {
  try {
    const findCSVMap = salesState.csvMappings.find(csv => csv.id === csvMapId);
    if (!findCSVMap) {
      throw new Error('CSV Map not found');
    }

    const columnMap = Object.keys((findCSVMap as ICSVMappingItem).input_csv.map).reduce((map, key) => {
      map[key] = findCSVMap.input_csv.map[key];
      return map;
    }, {} as Record<string, string>);

    const pointsOfSaleMapObjName = Object.keys((findCSVMap as ICSVMappingItem).point_of_sale_map).reduce((map, key) => {
      map[key] = findCSVMap.point_of_sale_map[key].name;
      return map;
    }, {} as Record<string, string>);

    const pointsOfSaleMapObjId = Object.keys((findCSVMap as ICSVMappingItem).point_of_sale_map).reduce((map, key) => {
      map[key] = findCSVMap.point_of_sale_map[key].id;
      return map;
    }, {} as Record<string, string>);

    if (Object.keys(columnMap).length > 0) {
      const updatedTransformedCSV = (salesState.csvSelected.papa.data as any[]).map((item: Record<string, any>) => {
        const filteredItem = Object.keys(item)
          .filter(key => key in columnMap)
          .reduce((obj, key) => {
            if (columnMap[key] === 'date') {
              const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
              const date = momenttz.tz(item[key], findCSVMap.date_format, timeZone).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
              obj[key] = date !== 'Invalid date' ? date : undefined;
            } else if (columnMap[key] === 'multibrand_point_of_sale') {
              obj[key] = pointsOfSaleMapObjName[item[key]]
              obj.point_of_sale_id = pointsOfSaleMapObjId[item[key]]
            } else if (columnMap[key] === 'hour') {
              const fractionOfDay = item[key];
              const millisecondsInADay = 24 * 60 * 60 * 1000;
              const milliseconds = fractionOfDay * millisecondsInADay;
              const readableTime = moment.utc(milliseconds).format('HH:mm:ss');
              obj[key] = readableTime !== 'Invalid date' ? readableTime : undefined;
            } else {
              obj[key] = item[key];
            }
            return obj;
          }, {} as Record<string, any>);

        const newItem: Record<string, any> = {
          ...filteredItem,
        };
        return newItem;
      }).filter(item => Object.values(item).some(value => value !== '' && value !== undefined && value !== null));

      for (const [index, item] of updatedTransformedCSV.entries()) {
        updatedTransformedCSV[index] = {
          multibrand: company.name,
          client_id: company.id,
          ...item,
        }
      }
      
      const saveFileFetch = await saveSaleFileAPI({
        csvMapId,
        dataJSON: updatedTransformedCSV,
        original_content_type: salesState.csvSelected.file.type,
        original_name: salesState.csvSelected.file.name,
        encoding: (salesState.csvSelected.papa.meta as any).encoding,
        token
      });

      if (saveFileFetch.response.status === 200) {
        try {
          const response = await fetch(saveFileFetch.data.raw_sales_presigned_url, {
            method: 'PUT',
            body: salesState.csvSelected.file,
            headers: {
              'Content-Type': 'application/octet-stream'
            }
          });
          if (response.status === 200) {
            return true
          } else {
            return false
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        notificationErr(salesError.save_sales_file_002, translate);
        return false;
      }
    }
    return false;
  } catch (error) {
    notificationErr(salesError.save_sales_file_001, translate);
  }
}