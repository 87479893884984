import { basePath } from "./utils/config"

export const pingAPI = async (token: string): Promise<any> => {
	const params = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	}
	const response = await fetch(`${basePath}/status/ping`, params)
	const data = await response.json()
	return { response, data }
}

export const getLanguagesAPI = async (token: string): Promise<any> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			filter: {},
			select: "",
			limit: 200,
			index: 0
		}),
	}
	const response = await fetch(`${basePath}/crud_language/findManyCrud_language`, params)
	const data = await response.json()
	return { response, data }
}

export type TBannerItemResponse = {
  id: string;
  brand_id: string;
  tenant_id: null | string;
  client_id: null | string;
  i18n_lang_id: string;
  name: string;
  multimedia_type: 'banner';
  title: string;
  description: string;
  file_type: 'image/jpeg' | 'image/png' | 'image/jpg';
  url: string;
  key: string;
  usage_id: string;
  visualization_id: null | string;
  copy_rights: boolean;
  enable_download: boolean;
  profile: boolean;
  start: string;
  end: string;
  createdAt: string;
  updatedAt: string;
};
type TGetBannersResponse = {
	response: Response,
	data: TBannerItemResponse[]
}
export const getBannersAPI = async (token: string): Promise<TGetBannersResponse> => {
	const params = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	}
	const response = await fetch(`${basePath}/multimedia/get_banners`, params)
	const data = await response.json()
	return { response, data }
}
