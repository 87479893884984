import { getTenantInfoAPI } from "api/brandFeed.api";

export async function getBrandFeedAction(dispatch: any, tenantId: string, token: string, translate: any) {
  try {
    const getTenantInfoFetch = await getTenantInfoAPI({ tenantId, token });
    console.log(getTenantInfoFetch);
    if (tenantId) {
      return true
    } else {
      return false
    }
  } catch (error) {
    console.log(error);
  }
}