/* eslint-disable react-hooks/exhaustive-deps */
import React, { useReducer, useMemo, useCallback, memo, useEffect, MutableRefObject } from 'react'
import CatalogDownloadContext from './CatalogDownloadContext'
import initialCatalogDownloadState from './initialCatalogDownloadState'
import catalogDownloadReducer from 'contexts/catalogDownload/reducer/catalogDownload.reducer'
import * as action from "contexts/catalogDownload/reducer/catalogDownload.actions";
import * as types from "contexts/catalogDownload/reducer/catalogDownload.types";
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import useContextUser from 'hooks/contexts/useContextUser';
import { ICatalogFetchCSVDataPlainSkuResponse, IInitialCatalogDownloadState, TCatalogDownloadFiltersSelected, TFiltersSelectsValues, TImageSelectionTypes, imageSelectionTypes } from 'interfaces/catalogDownload.interface';
import useContextMyBrands from 'hooks/contexts/useContextMyBrands';
import { IColumnsSelectOrderState } from 'views/pages/main/catalog/catalogDownloadPage/components/columnsSelectOrder/ColumnsSelectOrder';
import { DropResult } from 'react-beautiful-dnd';
import { ChildrenProps } from 'interfaces/general.interface';

export const catalogDownloadFiltersKeys = {
	brand: 'brand',
	color: 'color',
	season: 'season',
	division: 'division',
	family: 'family',
	gender: 'gender',
	segmentation: 'segmentation',
	price: 'price',
}

function CatalogDownloadProvider(props: ChildrenProps) {
	const [cdsState, dispatch] = useReducer(catalogDownloadReducer, initialCatalogDownloadState)
	const catalogDownloadState = cdsState as IInitialCatalogDownloadState
	const { getAccessTokenSilently } = useAuth0()
	const { t: translate } = useTranslation()
	const { dbUser, company } = useContextUser()
	const { connectedBrands } = useContextMyBrands()

	// Filters //////////////////
	useEffect(() => {
		(async () => {
			const token = await getAccessTokenSilently()
			action.getFiltersAction({ dispatch, connectedBrands, company, dbUser, token, translate })
		})()
	}, [connectedBrands, company.id, company.country?.id, dbUser.language?.id, translate])

	const setSelectedValues = useCallback((value: React.Key[]) => {
		dispatch({ type: types.SET_SELECTED_VALUES, payload: value })
	}, [])

	const applyFilterChanges = useCallback(async (value: TCatalogDownloadFiltersSelected) => {
		const token = await getAccessTokenSilently()
		return await action.applyFilterChangesAction({ dispatch, value, catalogDownloadState, dbUserLanguageId: dbUser.language.id, token, translate })
	}, [catalogDownloadState, dbUser.language?.id, translate])

	const cancelFilters = useCallback(() => {
		dispatch({ type: types.SET_FILTERS_SELECTED_TEMP, payload: catalogDownloadState.filtersSelected })
		dispatch({ type: types.SET_IS_OPEN_FILTERS, payload: false })
	}, [catalogDownloadState.filtersSelected])

	const cleanFilters = useCallback(() => {
		dispatch({ type: types.SET_FILTERS_SELECTED_TEMP, payload: {} as TCatalogDownloadFiltersSelected })
		dispatch({ type: types.SET_IS_OPEN_FILTERS, payload: false })
		dispatch({ type: types.SET_FILTERS_SELECTED, payload: {} as TCatalogDownloadFiltersSelected })
		dispatch({ type: types.SET_SELECTED_VALUES, payload: [] })
		dispatch({ type: types.SET_FILTERED_LIST, payload: [] })
		dispatch({ type: types.SET_FILTERS_TAGS, payload: [] })
		dispatch({ type: types.SET_PVI_SLIDER, payload: [] })
		dispatch({ type: types.SET_PVPR_SLIDER, payload: [] })
		dispatch({ type: types.SET_IMAGE_SELECTION, payload: imageSelectionTypes.all })
		dispatch({ type: types.SET_FAVORITES, payload: "" })
	}, [])

	const onChangeFilterSelect = useCallback((filterName: string, value: TFiltersSelectsValues[]) => {
		dispatch({
			type: types.SET_FILTERS_SELECTED_TEMP,
			payload: {
				...catalogDownloadState.filtersSelectedTemp,
				[filterName]: value
			}
		})
	}, [catalogDownloadState.filtersSelectedTemp])

	const openFilters = useCallback((value: boolean) => {
		dispatch({ type: types.SET_IS_OPEN_FILTERS, payload: value })
	}, [])

	const setImageSelection = useCallback((value: TImageSelectionTypes) => {
		dispatch({ type: types.SET_IMAGE_SELECTION, payload: value })
	}, [])

	const setFavorites = useCallback((value: boolean) => {
		dispatch({ type: types.SET_FAVORITES, payload: value === true ? dbUser.id : '' })
	}, [dbUser.id])

	const setFilteredList = useCallback((value: ICatalogFetchCSVDataPlainSkuResponse[]) => {
		dispatch({ type: types.SET_FILTERED_LIST, payload: value })
	}, [])

	const setPviSlider = useCallback((value: [number, number]) => {
		dispatch({ type: types.SET_PVI_SLIDER, payload: value[0] === 0 && value[1] === 0 ? [] : value })
	}, [])

	const setPvprSlider = useCallback((value: [number, number]) => {
		dispatch({ type: types.SET_PVPR_SLIDER, payload: value[0] === 0 && value[1] === 0 ? [] : value })
	}, [])

	// Columns //////////////////
	const moveColumnsOrder = useCallback((result: DropResult) => {
		return action.moveColumnsOrderAction({ dispatch, result, columnsOrder: catalogDownloadState.columnsOrder })
	}, [catalogDownloadState.columnsOrder])

	const checkColumn = useCallback((columnKey: string) => {
		return action.checkColumnAction({ dispatch, columnKey, columnsOrder: catalogDownloadState.columnsOrder })
	}, [catalogDownloadState.columnsOrder])

	// Search //////////////////
	const setQuerySearch = useCallback(async (value: string) => {
		dispatch({ type: types.SET_QUERY_SEARCH, payload: value })
	}, [])

	const applySearch = useCallback(async () => {
		const token = await getAccessTokenSilently()
		return action.applySearchAction({ dispatch, catalogDownloadState, translate, token })
	}, [catalogDownloadState, getAccessTokenSilently, translate])

	// Download CSV //////////////////
	const downloadCatalog = useCallback(async ({ completedDownloads }: { completedDownloads: MutableRefObject<number> }) => {
		return await action.donwloadCatalogAction({ dispatch, catalogDownloadState, completedDownloads, translate })
	}, [catalogDownloadState, translate])

	const cancelDownloadCatalog = useCallback(async ({ completedDownloads }: { completedDownloads: MutableRefObject<number> }) => {
		return await action.cancelDownloadCatalogAction({ dispatch, catalogDownloadState, completedDownloads })
	}, [catalogDownloadState])

	const setDownloadImages = useCallback(async (value: boolean, columns: Record<string, IColumnsSelectOrderState>) => {
		return await action.setDownloadImagesAction({ dispatch, value, columns })
	}, [])

	const memoProvider = useMemo(
		() => ({
			...catalogDownloadState,
			// Filters
			setFilteredList,
			setSelectedValues,
			openFilters,
			cancelFilters,
			cleanFilters,
			applyFilterChanges,
			onChangeFilterSelect,
			setImageSelection,
			setFavorites,
			setPviSlider,
			setPvprSlider,
			// Columns
			moveColumnsOrder,
			checkColumn,
			// Search
			setQuerySearch,
			applySearch,
			// Download
			downloadCatalog,
			cancelDownloadCatalog,
			setDownloadImages,
		}), [
		catalogDownloadState,
		// Filters
		setFilteredList,
		setSelectedValues,
		openFilters,
		cancelFilters,
		cleanFilters,
		applyFilterChanges,
		onChangeFilterSelect,
		setImageSelection,
		setFavorites,
		setPviSlider,
		setPvprSlider,
		// Columns
		moveColumnsOrder,
		checkColumn,
		// Search
		setQuerySearch,
		applySearch,
		// Download
		downloadCatalog,
		cancelDownloadCatalog,
		setDownloadImages,
	]
	);

	return (
		<CatalogDownloadContext.Provider value={memoProvider}>
			{props.children}
		</CatalogDownloadContext.Provider>
	)
}

export default memo(CatalogDownloadProvider)