/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useCallback, useEffect, useMemo, useReducer } from 'react';
import MultimediaContext from './MultimediaContext'
import initialMultimediaState from './initialMultimediaState'
import userReducer from 'contexts/multimedia/reducer/multimedia.reducer'
import * as action from "contexts/multimedia/reducer/multimedia.actions";
import { useTranslation } from 'react-i18next';
import { IMyBrand } from 'interfaces/myBrands.interface';
import { useAuth0 } from '@auth0/auth0-react';
import { MultimediaTypes, TInitialMultimediaState, TMediaState } from 'interfaces/multimedia.interface';
import { abortCatalogController } from 'utils/abortController';
import useContextMyBrands from 'hooks/contexts/useContextMyBrands';
import * as MTypes from './reducer/multimedia.types'
import useContextUser from 'hooks/contexts/useContextUser';
import { ChildrenProps } from 'interfaces/general.interface';

function MultimediaProvider(props: ChildrenProps) {
	const [mma, dispatch] = useReducer(userReducer, initialMultimediaState)
	const multimedia = mma as TInitialMultimediaState
	const { connectedBrands, isLoading } = useContextMyBrands()
	const { company } = useContextUser()
	const { getAccessTokenSilently } = useAuth0()
	const { t: translate } = useTranslation()
	const myBrandsIds = useMemo(() => connectedBrands.map((item: IMyBrand) => item.brand.id), [connectedBrands])

	useEffect(() => {
		if (!company.id) return
		dispatch({ type: MTypes.RESET_MEDIA })
	}, [company.id])

	const getMedia = useCallback(async (type: MultimediaTypes) => {
		const signal = abortCatalogController()
		const token = await getAccessTokenSilently()
		!isLoading && action.getMediaAction(dispatch, ((multimedia[type as keyof typeof multimedia] as TMediaState)), myBrandsIds, type, signal, translate, token)
	}, [myBrandsIds, isLoading, multimedia, translate])

	const getMoreMedia = useCallback(async (type: MultimediaTypes) => {
		const signal = abortCatalogController()
		const token = await getAccessTokenSilently()
		myBrandsIds.length > 0 && action.getMoreMediaAction(dispatch, ((multimedia[type as keyof typeof multimedia] as TMediaState)), myBrandsIds, type, signal, translate, token)
	}, [myBrandsIds, multimedia, translate])

	const getVideosByBrand = useCallback(async (brandId: string) => {
		const signal = abortCatalogController()
		const token = await getAccessTokenSilently()
		action.getVideosByBrandAction(dispatch, brandId, signal, translate, token)
	}, [translate])

	const searchMedia = useCallback(async (query: string, type: MultimediaTypes) => {
		const signal = abortCatalogController()
		const token = await getAccessTokenSilently()
		action.searchMediaAction(dispatch, ((multimedia[type as keyof typeof multimedia] as TMediaState)), query, type, signal, translate, token)
	}, [multimedia, translate])

	const setBrandSelected = useCallback(async (brand: IMyBrand, type: MultimediaTypes) => {
		const signal = abortCatalogController()
		const token = await getAccessTokenSilently()
		myBrandsIds.length > 0 && action.setBrandSelectedAction(dispatch, brand, myBrandsIds, type, ((multimedia[type as keyof typeof multimedia] as TMediaState)), signal, translate, token)
	}, [multimedia, myBrandsIds, translate])

	const memoProvider = useMemo(
		() => ({
			...multimedia,
			getMedia,
			getMoreMedia,
			getVideosByBrand,
			searchMedia,
			setBrandSelected
		}), [
		multimedia,
		getMedia,
		getMoreMedia,
		getVideosByBrand,
		searchMedia,
		setBrandSelected
	]
	);

	return (
		<MultimediaContext.Provider value={memoProvider}>
			{props.children}
		</MultimediaContext.Provider>
	)
}

export default memo(MultimediaProvider)