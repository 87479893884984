import { IInitialSalesState } from 'interfaces/sales.interface';
import * as CatalogCsvUploadType from './sales.types'

export default function salesReducer(state: IInitialSalesState, action: any) {
	const { type, payload } = action;

	switch (type) {
		case CatalogCsvUploadType.SET_SALES_HISTORY:
			return {
				...state,
				history: payload
			}

		case CatalogCsvUploadType.SET_IS_LOADING_HISTORY:
			return {
				...state,
				isLoadingHistory: payload
			}

		case CatalogCsvUploadType.SET_REMOVE_UPLOAD:
			return {
				...state,
				history: state.history.filter((report) => report.id !== payload)
			}

		case CatalogCsvUploadType.SET_IS_LOADING_REJECT_UPLOAD:
			return {
				...state,
				isLoadingRemoveUpload: {
					isLoading: payload.isLoading,
					uploadId: payload.uploadId
				}
			}

		case CatalogCsvUploadType.SET_CSV_SELECTED:
			return {
				...state,
				csvSelected: {
					papa: payload.papa,
					file: payload.file
				}
			}

		case CatalogCsvUploadType.SET_CSV_SELECTED_CSV_DATA:
			return {
				...state,
				csvSelected: {
					...state.csvSelected,
					papa: {
						...state.csvSelected.papa,
						data: payload.csvData
					}
				}
			}

		case CatalogCsvUploadType.SET_IS_LOADING_CSV_SELECTED:
			return {
				...state,
				isLoadingCSVSelected: payload
			}

		case CatalogCsvUploadType.SET_CSV_MAPPINGS:
			return {
				...state,
				csvMappings: payload
			}

		case CatalogCsvUploadType.SET_CSV_MAPPING_KEYS:
			return {
				...state,
				csvMappingKeys: payload
			}

		case CatalogCsvUploadType.SET_IS_LOADING_CSV_MAPPINGS:
			return {
				...state,
				isLoadingCSVMappings: payload
			}

		case CatalogCsvUploadType.DELETE_MAPPING:
			return {
				...state,
				csvMappings: state.csvMappings.filter((mapping) => mapping.id !== payload)
			}

		case CatalogCsvUploadType.CREATE_MAPPING:
			return {
				...state,
				csvMappings: [...state.csvMappings, payload]
			}

		case CatalogCsvUploadType.SET_SEND_FILE:
			return {
				...state,
				sendFile: payload
			}

		default:
			return state
	}
}