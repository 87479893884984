// import logoLacoste from "assets/temporary_files/brands-logos/logo-Lacoste.png"
// import logoEcoalf from "assets/temporary_files/brands-logos/Ecoalf.jpg"
// import fullLogoHub4Retail from "assets/img/full-logo.svg"
// import logoHub4Retail from "assets/img/logo192.png"

export type Notification = {
	id: string
	summary: string
	message: string
	icon: string
	read: boolean
	complete?: string
	abort?: string
	details?: {
		type: string // catalog | profile-brand
		id: string // catalogId | profileBrandId
	}
	createdAt: string
	updatedAt: string
}

export type Alert = {
	id: string
	summary: string
	message: string
	read: boolean
	icon: string
	createdAt: string
	updatedAt: string
}

type InitialState = {
	notifications: Notification[],
	alerts: Alert[],
	markAsReaded: (type: string, notificationId: string, read: boolean) => void,
}

const initialNotificationsState: InitialState = {
	// notifications: [
	// 	{
	// 		id: "0",
	// 		summary: "¡Bienvenido a Hub4Retail! Comienza tu colaboración con tus clientes",
	// 		message: "¡Bienvenido a Hub4Retail! Comienza tu colaboración con tus clientes",
	// 		icon: logoHub4Retail,
	// 		read: false,
	// 		createdAt: '2023-10-31T13:32:39.825Z',
	// 		updatedAt: '2023-10-31T13:32:39.825Z'
	// 	},
	// 	{
	// 		id: "1",
	// 		summary: "¡<Lacoste> ha aceptado tu solicitud!",
	// 		message: "¡<Lacoste> ha aceptado tu solicitud!",
	// 		icon: logoLacoste,
	// 		read: false,
	// 		details: {
	// 			type: 'profile-brand',
	// 			id: "fd100cde-8e8a-4c36-ad42-9ed9e533a5ad"
	// 		},
	// 		createdAt: '2023-09-03T12:32:39.825Z',
	// 		updatedAt: '2023-09-03T12:32:39.825Z'
	// 	},
	// 	{
	// 		id: "11",
	// 		summary: "¡<Ecoalf> ha aceptado tu solicitud!",
	// 		message: "¡<Ecoalf> ha aceptado tu solicitud!",
	// 		icon: logoEcoalf,
	// 		read: false,
	// 		details: {
	// 			type: 'profile-brand',
	// 			id: "0228749f-22a1-4ec3-80e0-ad83e6aab9cf"
	// 		},
	// 		createdAt: '2023-09-03T12:32:39.825Z',
	// 		updatedAt: '2023-09-03T12:32:39.825Z'
	// 	},
	// 	{
	// 		id: "2",
	// 		summary: "¡<brand> ha rechazado tu solicitud!",
	// 		message: "¡<brand> ha rechazado tu solicitud!",
	// 		icon: logoEcoalf,
	// 		read: false,
	// 		createdAt: '2023-09-03T12:32:39.825Z',
	// 		updatedAt: '2023-09-03T12:32:39.825Z'
	// 	},
	// 	{
	// 		id: "3",
	// 		summary: "<brand> ha subido nuevos productos.",
	// 		message: "<brand> ha subido nuevos productos.",
	// 		icon: logoLacoste,
	// 		read: false,
	// 		details: {
	// 			type: 'catalog',
	// 			id: "c47ce5d0-e89f-4e5e-88e5-fc98148ebc9b"
	// 		},
	// 		createdAt: '2023-08-03T12:32:39.825Z',
	// 		updatedAt: '2023-08-03T12:32:39.825Z'
	// 	},
	// 	{
	// 		id: "4",
	// 		summary: "<brand> ha dejado de compartir datos en <punto de venta>",
	// 		message: "<brand> ha dejado de compartir datos en <punto de venta>.",
	// 		icon: logoLacoste,
	// 		read: false,
	// 		createdAt: '2023-10-04T12:32:39.825Z',
	// 		updatedAt: '2023-10-04T12:32:39.825Z'
	// 	},
	// ],
	// alerts: [
	// 	{
	// 		id: '1',
	// 		summary: "Has dejado de tener conexión con <punto de venta>",
	// 		message: "Has dejado de tener conexión con <punto de venta>",
	// 		read: false,
	// 		icon: logoLacoste,
	// 		createdAt: '2023-10-10T12:32:39.825Z',
	// 		updatedAt: '2023-10-10T12:32:39.825Z'
	// 	},
	// 	{
	// 		id: '2',
	// 		summary: "<punto de venta> lleva 48 horas sin enviar datos",
	// 		message: "<punto de venta> lleva 48 horas sin enviar datos",
	// 		read: false,
	// 		icon: logoEcoalf,
	// 		createdAt: '2023-10-30T12:32:39.825Z',
	// 		updatedAt: '2023-10-30T12:32:39.825Z'
	// 	},
	// ],
	notifications: [],
	alerts: [],
	markAsReaded: (type: string, notificationId: string, read: boolean) => { },
}

export default initialNotificationsState