import { useAuth0 } from "@auth0/auth0-react"
import useContextMyBrands from "hooks/contexts/useContextMyBrands"
import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { messageInfo } from "views/components/UI/message"
import * as path from 'router/paths'

export const LoginRequired = ({ children }: { children: React.ReactElement }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()
  // const navigate = useNavigate()

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect()
      // navigate('/authentication/signup')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated])

  return children
}

export const NoCatalogRedirectToHome = ({ children }: { children: React.ReactElement }) => {
  const { connectedBrands, isLoading } = useContextMyBrands()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    let isMounted = true
    if (isMounted && !isLoading && connectedBrands.length === 0 && location.pathname === path.catalog) {
      messageInfo('No hay catálogos disponibles, por favor, añade una asociación para obtener su catálogo.', 5)
      setTimeout(() => {
        navigate(path.home)
      }, 1000)
      return
    }
    return () => { isMounted = false }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedBrands.length, location.pathname])

  return children
}