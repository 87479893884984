export const GET_CATALOG = 'GET_CATALOG'
export const ADD_MORE_TO_CATALOG = 'ADD_MORE_TO_CATALOG'
export const UPDATE_CATALOG = 'UPDATE_CATALOG'
export const DELETE_CATALOG = 'DELETE_CATALOG'
export const GET_FILTER = 'GET_FILTER'
export const GET_COLLECTION = 'GET_COLLECTION'
export const GER_PRODUCT = 'GER_PRODUCT'
export const SET_IS_LOADING = 'SET_IS_LOADING'
export const SET_IS_LOADING_MORE_PRODUCTS = 'SET_IS_LOADING_MORE_PRODUCTS'
export const SET_IS_LOADING_RELATED_PRODUCTS = 'SET_IS_LOADING_RELATED_PRODUCTS'
export const SET_BODY_PRODUCT = 'SET_BODY_PRODUCT'
export const SET_BODY_PRODUCT_SEARCH = 'SET_BODY_PRODUCT_SEARCH'
export const SET_BODY_PRODUCT_SEARCH_TEMP = 'SET_BODY_PRODUCT_SEARCH_TEMP'
export const SET_QUERY_SEARCH = 'SET_QUERY_SEARCH'
export const ADD_BODY_FILTERS = 'ADD_BODY_FILTERS'
export const REMOVE_BODY_FILTERS = 'REMOVE_BODY_FILTERS'
export const ADD_TO_QUERY_SEARCH_ARRAY = 'ADD_TO_QUERY_SEARCH_ARRAY'
export const REMOVE_FROM_QUERY_SEARCH_ARRAY = 'REMOVE_TO_QUERY_SEARCH_ARRAY'
export const SET_BODY_SEARCH_FILTER = 'SET_BODY_SEARCH_FILTER'
export const RESET_BODY_FILTERS = 'RESET_BODY_FILTERS'
export const INCREMENT_PAGINATION_INDEX = 'INCREMENT_PAGINATION_INDEX'
export const SET_THERE_ARE_MORE_PRODUCTS = 'SET_THERE_ARE_MORE_PRODUCTS'
export const SET_IS_EMPTY_PRODUCTS = 'SET_IS_EMPTY_PRODUCTS'
export const ADD_LIKE_TO_PRODUCT = 'ADD_LIKE_TO_PRODUCT'
export const REMOVE_LIKE_TO_PRODUCT = 'REMOVE_LIKE_TO_PRODUCT'
export const SET_SORT_BY = 'SET_SORT_BY'
export const SET_PRICE_SLIDERS = 'SET_PRICE_SLIDERS'
export const SET_BRANDS_PRODUCTS = 'SET_BRANDS_PRODUCTS'
export const SET_BRAND_SELECTED = 'SET_BRAND_SELECTED'
export const SET_FAVORITES = 'SET_FAVORITES'
export const SET_FILTERS_LENGTHS = 'SET_FILTERS_LENGTHS'
export const SET_QUERY_SEARCH_ARRAY = 'SET_QUERY_SEARCH_ARRAY'
export const SET_PRODUCT_BRAND_ID = 'SET_PRODUCT_BRAND_ID'
export const SET_RELATED_PRODUCTS = 'SET_RELATED_PRODUCTS'
export const SET_IS_LOADING_PRODUCT = 'SET_IS_LOADING_PRODUCT'
export const RESET_PRICE_SLIDER = 'RESET_PRICE_SLIDER'
export const RESET_CATALOG_ITEMS = 'RESET_CATALOG_ITEMS'
export const RESET_TEMP_BODY_FILTERS = 'RESET_TEMP_BODY_FILTERS'
export const ADD_BODY_BRAND_OPTIONS = 'ADD_BODY_BRAND_OPTIONS'
export const REMOVE_BODY_BRAND_OPTIONS = 'REMOVE_BODY_BRAND_OPTIONS'