import { IinitialMyBrandsState } from 'interfaces/myBrands.interface';
import { connected } from '../constants';
import * as MyBrandsTypes from './myBrands.types'

export default function myBrandsReducer(state: IinitialMyBrandsState, action: any) {
	const { type, payload } = action;

	switch (type) {
		case MyBrandsTypes.GET_MY_BRANDS:
			return {
				...state,
				myBrands: payload,
				connectedBrands: payload.filter((brand: any) => brand.state_name === connected),
			}

		case MyBrandsTypes.ADD_TO_MY_BRAND:
			return {
				...state,
				myBrands: payload
			}

		case MyBrandsTypes.SET_IS_LOADING_ADD_TO_MY_BRAND:
			return {
				...state,
				isLoadingAddToMyBrand: {
					isLoading: payload.isLoading,
					brandId: payload.brandId
				}
			}

		case MyBrandsTypes.REMOVE_FROM_MY_BRAND:
			return {
				...state,
				myBrands: payload
			}

		case MyBrandsTypes.SET_IS_LOADING:
			return {
				...state,
				isLoading: payload
			}

		case MyBrandsTypes.CREATE_NEW_BRAND:
			return {
				...state,
				myBrands: [payload, ...state.myBrands]
			}

		case MyBrandsTypes.SET_POINTS_OF_SALE_CONNECTED:
			return {
				...state,
				pointsOfSaleConnected: payload
			}

		case MyBrandsTypes.SET_ADD_TO_MY_BRAND_MULTIPLE_STATUS:
			return {
				...state,
				atmbm: {
					...state.atmbm,
					...payload,
				}
			}

		default:
			return state
	}
}