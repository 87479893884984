import * as PointsOfSaleTypes from './pointsOfSale.types'
import * as api from 'api/pointsOfSale.api';
import { pointsOfSaleError } from 'utils/errorCodes';
import { notificationErr, notificationError } from 'views/components/UI/notification';
import { messageError, messageSuccess, messageWarning } from 'views/components/UI/message';
import { IPointOfSaleExchangesState, IPointOfSaleState, IPointsOfSaleCategoryState } from 'interfaces/pointsOfSale.interface';
import { TCountryItem } from 'interfaces/countries.interface';

export async function getPointsOfSaleAction(dispatch: any, clientId: string, token: string, translate: any) {
	dispatch({ type: PointsOfSaleTypes.IS_LOADING_GET_POINTS_OF_SALE, payload: true })
	try {
		const [pointsOfSaleFetch] = await Promise.all([
			api.getPointsOfSaleAPI(clientId, token),
		])

		if (pointsOfSaleFetch.response.status === 200) {
			const sortPointsOfSale = pointsOfSaleFetch.data.point_of_sales.sort((a, b) => a.name?.localeCompare(b.name))
			return dispatch({
				type: PointsOfSaleTypes.GET_POINTS_OF_SALE,
				payload: sortPointsOfSale
			})
		} else {
			messageError('Error al obtener los puntos de venta.')
			return notificationError({
				msg: translate('notification-error_message'),
				description: translate('notification-error_description'),
				errorCode: pointsOfSaleError.get_points_of_sale_002
			})
		}
	} catch (err) {
		return notificationErr(pointsOfSaleError.get_points_of_sale_001, translate)
	} finally {
		dispatch({ type: PointsOfSaleTypes.IS_LOADING_GET_POINTS_OF_SALE, payload: false })
	}
}

export async function getCategoriesAction(dispatch: any, token: string) {
	try {
		const [pointsOfSaleCategoriesFetch] = await Promise.all([
			api.getPointsOfSaleCategoriesAPI(token)
		])
		if (pointsOfSaleCategoriesFetch.response.status === 200) {
			const categories: IPointsOfSaleCategoryState[] = pointsOfSaleCategoriesFetch.data.map((data) => {
				return { value: data.id, label: data.name, online: data.online }
			})
			dispatch({
				type: PointsOfSaleTypes.SET_POINTS_OF_SALE_CATEGORIES,
				payload: categories
			})
		}
	} catch (err) {
		console.log('error', err);
	}
}

export async function selectPointOfSaleAction(dispatch: any, pointOfSale: IPointOfSaleState, translate: any) {
	try {
		return dispatch({
			type: PointsOfSaleTypes.SELECT_POINT_OF_SALE,
			payload: pointOfSale
		})
	} catch (err) {
		messageError('Error al seleccionar el punto de venta.')
		return notificationErr(pointsOfSaleError.select_point_of_sale_001, translate)
	}
}

export async function createPointOfSaleAction(dispatch: any, newPointOfSale: any, token: string, translate: any, pointsOfSale: any, setIsOpenDrawer: (boolean: boolean) => void, resetGoogleAddress: any, setShowOnlineSelect: any, form: any, setBrandsAddSelected: any) {
	try {
		const brandsToAdd = newPointOfSale.brandsToAdd
		delete newPointOfSale.category_id_online
		delete newPointOfSale.brandsToAdd
		const body = {
			brand_works: brandsToAdd.map((item: any) => item.id),
			point_of_sale: newPointOfSale
		}
		const createFetch = await api.createPointsOfSaleAPI(body, token)
		if (createFetch.response.status === 201 || createFetch.response.status === 200) {
			const pointOfSale: IPointOfSaleState = {
				...createFetch.data.point_of_sale,
				point_of_sale_brand_works: brandsToAdd.map((item: any) => {
					return {
						brand_id: item.id,
						brand: {
							name: item.name,
							logo: item.logo
						}
					}
				})
			}
			messageSuccess('Punto de venta creado correctamente.')
			const newPointsOfSale: IPointOfSaleState[] = [...pointsOfSale, pointOfSale]
			const sortPointsOfSale = newPointsOfSale.sort((a, b) => a.name?.localeCompare(b.name))
			dispatch({
				type: PointsOfSaleTypes.CREATE_POINT_OF_SALE,
				payload: sortPointsOfSale
			})
			setIsOpenDrawer(false)
			resetGoogleAddress()
			setShowOnlineSelect(false)
			form.resetFields()
			setBrandsAddSelected([])
			return
		} else {
			messageError('Error al crear el punto de venta.')
			return notificationErr(pointsOfSaleError.create_point_of_sale_002, translate)
		}
	} catch (err) {
		messageError('Error al crear el punto de venta.')
		return notificationErr(pointsOfSaleError.create_point_of_sale_001, translate)
	}
}

export async function updatePointOfSaleAction(dispatch: any, newPointOfSale: any, pointsOfSale: IPointOfSaleState[], countries: TCountryItem[], token: string, translate: any, setIsOpenDrawer: (boolean: boolean) => void) {
	try {
		const brandsToAdd = newPointOfSale.brandsToAdd
		if (newPointOfSale.online === true) {
			newPointOfSale.address = ''
			newPointOfSale.province = ''
			newPointOfSale.postal_code = ''
			newPointOfSale.lat = 0
			newPointOfSale.lng = 0
			newPointOfSale.floor = ''
			newPointOfSale.door = ''
			delete newPointOfSale.country_id
			// newPointOfSale.country_id = ''
			newPointOfSale.city = ''
			newPointOfSale.area = null
		}
		const body = {
			filter: { id: newPointOfSale.id },
			update: newPointOfSale,
			brand_works_id: brandsToAdd.map((item: any) => item.id)
		}
		delete newPointOfSale.brandsToAdd
		const updateFetch = await api.updatePointOfSaleAPI(body, token)
		if (updateFetch.response.status === 200) {
			const { country_id } = newPointOfSale
			const findPointOfSale = pointsOfSale.findIndex((item: any) => item.id === newPointOfSale.id)
			const newObj: IPointOfSaleState = {
				...newPointOfSale,
				point_of_sale_brand_works: brandsToAdd.map((item: any) => {
					return {
						brand_id: item.id,
						brand: {
							name: item.name,
							logo: item.logo
						}
					}
				}),
				country: {
					id: country_id,
					name: countries.find((country) => country.id === country_id)?.name as string
				}
			}
			pointsOfSale[findPointOfSale] = newObj
			const sortPointsOfSale = pointsOfSale.sort((a: any, b: any) => a.name?.localeCompare(b.name))
			messageSuccess('Punto de venta actualizado correctamente.')
			dispatch({
				type: PointsOfSaleTypes.UPDATE_POINT_OF_SALE,
				payload: sortPointsOfSale
			})
			return setIsOpenDrawer(false)
		} else {
			setIsOpenDrawer(false)
			messageError('Error al actualizar el punto de venta.')
			return notificationErr(pointsOfSaleError.update_point_of_sale_002, translate)
		}
	} catch (err) {
		messageError('Error al actualizar el punto de venta.')
		return notificationErr(pointsOfSaleError.update_point_of_sale_001, translate)
	}
}

export async function removePointOfSaleAction(dispatch: any, pointOfSaleId: string, token: string, translate: any) {
	try {
		const removeFetch = await api.removePointOfSaleAPI(pointOfSaleId, token)
		if (removeFetch.response.status === 403) {
			messageWarning('Este punto de venta hace referencia a productos y no se puede eliminar.')
			return false
		}
		if (removeFetch.data.point_of_sale > 0) {
			messageSuccess('Punto de venta eliminado correctamente.')
			dispatch({
				type: PointsOfSaleTypes.REMOVE_POINT_OF_SALE,
				payload: pointOfSaleId
			})
			return true
		} else {
			messageError('Error al eliminar el punto de venta.')
			notificationErr(pointsOfSaleError.remove_point_of_sale_002, translate)
			return false
		}
	} catch (err) {
		messageError('Error al eliminar el punto de venta.')
		notificationErr(pointsOfSaleError.remove_point_of_sale_001, translate)
		return false
	}
}

export async function updatePointOfSaleConnectionsAction(dispatch: any, key: string, value: boolean, translate: any) {
	return dispatch({
		type: PointsOfSaleTypes.UPDATE_POINT_OF_SALE_CONNECTIONS,
		payload: { key, value }
	})
}

export async function getExchangesAction(dispatch: any, translate: any, token: string) {
	try {
		const exchangesFetch = await api.getExchangesAPI(token)
		if (exchangesFetch.response.status === 200) {
			const addFieldsAndSort: IPointOfSaleExchangesState[] = exchangesFetch.data.map((item: IPointOfSaleExchangesState) => {
				return {
					...item,
					value: item.id,
					label: `${item.currency} - ${item.code}`
				}
			}).sort((a: IPointOfSaleExchangesState, b: IPointOfSaleExchangesState) => a.currency?.localeCompare(b.currency))
			return dispatch({
				type: PointsOfSaleTypes.SET_EXCHANGES,
				payload: addFieldsAndSort
			})
		} else {
			messageError('Error al obtener los tipos de monedas.')
			return notificationErr(pointsOfSaleError.get_exchanges_002, translate)
		}
	} catch (err) {
		messageError('Error al obtener los tipos de monedas.')
		return notificationErr(pointsOfSaleError.get_exchanges_001, translate)
	}
}