/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer, useMemo, useCallback, memo, useEffect } from 'react'
import PointsOfSaleContext from './PointsOfSaleContext'
import initialPointsOfSaleState from './initialPointsOfSaleState'
import pointsOfSaleReducer from './reducer/pointsOfSale.reducer'
import * as action from "./reducer/pointsOfSale.actions";
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { IPointsOfSaleStateInitialState, IPointOfSaleState } from 'interfaces/pointsOfSale.interface';
import useContextUser from 'hooks/contexts/useContextUser';
import useContextCountries from 'hooks/contexts/UI/useContextCountries';
import { ChildrenProps } from 'interfaces/general.interface';

function PointsOfSaleProvider(props: ChildrenProps) {
	const [psState, dispatch] = useReducer(pointsOfSaleReducer, initialPointsOfSaleState)
	const pointsOfSaleState: IPointsOfSaleStateInitialState = psState
	const { getAccessTokenSilently, isAuthenticated } = useAuth0()
	const { t: translate } = useTranslation()
	const { company, dbUser } = useContextUser()
	const { countries } = useContextCountries()

	useEffect(() => {
		(async () => {
			const token = await getAccessTokenSilently()
			if (token) {
				return action.getCategoriesAction(dispatch, token)
			}
		})()
	}, [])

	useEffect(() => {
		if (!company.id || !isAuthenticated) return
		(async () => {
			const token = await getAccessTokenSilently()
			action.getPointsOfSaleAction(dispatch, company.id, token, translate)
		})()
	}, [company.id, isAuthenticated])

	useEffect(() => {
		if (pointsOfSaleState.exchanges[0]?.id || !isAuthenticated || !dbUser.id) return
		(async () => {
			const token = await getAccessTokenSilently()
			action.getExchangesAction(dispatch, translate, token)
		})()
	}, [dbUser.id, isAuthenticated, pointsOfSaleState.exchanges])

	const selectPointOfSale = useCallback((pointOfSale: IPointOfSaleState) => {
		action.selectPointOfSaleAction(dispatch, pointOfSale, translate)
	}, [translate])

	const createPointOfSale = useCallback(async (
		pointOfSale: any,
		setIsOpenDrawer: (value: boolean) => void,
		resetGoogleAddress: any,
		setShowOnlineSelect: any,
		form: any,
		setBrandsAddSelected: any
	) => {
		const token = await getAccessTokenSilently()
		pointOfSale.client_id = company.id
		action.createPointOfSaleAction(
			dispatch,
			pointOfSale,
			token,
			translate,
			pointsOfSaleState.pointsOfSale,
			setIsOpenDrawer,
			resetGoogleAddress,
			setShowOnlineSelect,
			form,
			setBrandsAddSelected
		)
	}, [translate, company.id, pointsOfSaleState.pointsOfSale])

	const updatePointOfSale = useCallback(async (newPointOfSale: IPointOfSaleState, setIsOpenDrawer: (value: boolean) => void) => {
		const token = await getAccessTokenSilently()
		action.updatePointOfSaleAction(dispatch, newPointOfSale, pointsOfSaleState.pointsOfSale, countries, token, translate, setIsOpenDrawer)
	}, [pointsOfSaleState.pointsOfSale, countries, translate])

	const removePointOfSale = useCallback(async (pointOfSaleId: string) => {
		const token = await getAccessTokenSilently()
		return action.removePointOfSaleAction(dispatch, pointOfSaleId, token, translate) as Promise<boolean>
	}, [translate])

	const updatePointOfSaleConnections = useCallback(async (key: string, value: boolean) => {
		action.updatePointOfSaleConnectionsAction(dispatch, key, value, translate)
	}, [translate])

	const memoProvider = useMemo(
		() => ({
			...pointsOfSaleState,
			selectPointOfSale,
			createPointOfSale,
			updatePointOfSale,
			removePointOfSale,
			updatePointOfSaleConnections
		}), [
		pointsOfSaleState,
		selectPointOfSale,
		createPointOfSale,
		updatePointOfSale,
		removePointOfSale,
		updatePointOfSaleConnections
	]);

	return (
		<PointsOfSaleContext.Provider value={memoProvider}>
			{props.children}
		</PointsOfSaleContext.Provider>
	)
}

export default memo(PointsOfSaleProvider)