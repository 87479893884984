import { clientProdKey, clientStagingKey } from "utils/constants"
const DEV_API = 'https://platform-dev.hub4retail.com'
const STAGING_API = 'https://platform-staging.hub4retail.com'
const PROD_API = 'https://platform-prod.hub4retail.com'

function defaultBasePath() {
  let url = DEV_API
  if (process.env.REACT_APP_NODE_ENV === clientProdKey) {
    url = PROD_API
  } else if (process.env.REACT_APP_NODE_ENV === clientStagingKey) {
    url = STAGING_API
  }
  return url
}

export const basePath = defaultBasePath()
// export const basePath = STAGING_API
// export const basePath = PROD_API

export const basePathUploadService = 'https://platform-demo.hub4retail.com'
export const basePathSftpService = 'https://sftp-api.hub4retail.com'