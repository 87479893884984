import { IInitialBrandsState } from 'interfaces/brands.interface';
import * as BrandsTypes from './brands.types'

export default function brandsReducer(state: IInitialBrandsState, action: any) {
	const { type, payload } = action;

	switch (type) {
		case BrandsTypes.GET_PAGINATION_BRANDS:
			return {
				...state,
				brandsWithPagination: payload,
				bodyFetch: {
					...state.bodyFetch,
					index: payload.length
				}
			}

		case BrandsTypes.GET_ALL_BRANDS:
			return {
				...state,
				allBrands: payload,
			}

		case BrandsTypes.ADD_MORE_BRANDS:
			return {
				...state,
				brandsWithPagination: state.brandsWithPagination.concat(payload.data),
				bodyFetch: {
					...state.bodyFetch,
					index: payload.index + payload.data.length
				}
			}

		case BrandsTypes.SET_THERE_ARE_MORE_BRANDS:
			return {
				...state,
				thereAreMoreBrands: payload,
			}

		case BrandsTypes.SET_IS_LOADING_MORE_BRANDS:
			return {
				...state,
				isLoadingMoreBrands: payload,
			}

		case BrandsTypes.SET_BODY_SEARCH:
			return {
				...state,
				bodyFetch: {
					...state.bodyFetch,
					search: payload
				},
			}

		case BrandsTypes.RESET_BODY_SEARCH:
			return {
				...state,
				bodyFetch: {
					...state.bodyFetch,
					search: ''
				},
			}

		case BrandsTypes.SET_IS_LOADING:
			return {
				...state,
				isLoading: payload,
			}

		default:
			return state
	}
}