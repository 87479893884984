import * as CountriesTypes from './countries.types'

export default function countriesReducer(state: any, action: any) {
	const { type, payload } = action;

	switch (type) {
		case CountriesTypes.GET_COUNTRIES:
			return {
				...state,
				countries: payload
			}

		default:
			return state
	}
}