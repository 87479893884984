import { mediaDefaultLimit } from '../initialMultimediaState';
import * as MultimediaTypes from './multimedia.types'

export default function multimediaReducer(state: any, action: any) {
	const { type, payload } = action;

	switch (type) {
		case MultimediaTypes.GET_MULTIMEDIA:
			return {
				...state,
				[payload.type]: {
					...state[payload.type],
					items: state[payload.type].items.concat(payload.items),
					bodyMultimediaFetch: {
						...state[payload.type].bodyMultimediaFetch,
						options: {
							...state[payload.type].bodyMultimediaFetch.options,
							index: state[payload.type].bodyMultimediaFetch.options.index + payload.items.length
						}
					},
					thereAreMoreMedia: payload.items.length < mediaDefaultLimit ? false : true
				}
			}

		case MultimediaTypes.GET_MULTIMEDIA_BY_BRAND:
			return {
				...state,
				[payload.type]: {
					...state[payload.type],
					items: payload.items,
					bodyMultimediaFetch: {
						...state[payload.type].bodyMultimediaFetch,
						options: {
							...state[payload.type].bodyMultimediaFetch.options,
							index: state[payload.type].bodyMultimediaFetch.options.index + payload.items.length
						}
					},
					thereAreMoreMedia: payload.items.length < mediaDefaultLimit ? false : true
				}
			}

		case MultimediaTypes.SET_IS_LOADING_MEDIA:
			return {
				...state,
				[payload.type]: {
					...state[payload.type],
					isLoading: payload.isLoading
				}
			}

		case MultimediaTypes.SET_IS_LOADING_MORE_MEDIA:
			return {
				...state,
				[payload.type]: {
					...state[payload.type],
					isLoadingMoreMedia: payload.isLoading
				}
			}

		case MultimediaTypes.SET_BRAND_SELECTED:
			return {
				...state,
				[payload.type]: {
					...state[payload.type],
					brandSelected: {
						id: payload.brand.id,
						name: payload.brand.name,
						logo: payload.brand.logo
					},
					thereAreMoreMedia: true
				}
			}

		case MultimediaTypes.RESET_BRAND_SELECTED:
			return {
				...state,
				[payload]: {
					...state[payload],
					brandSelected: {
						id: '',
						name: '',
						logo: ''
					},
					thereAreMoreMedia: true
				}
			}

		case MultimediaTypes.SEARCH_MEDIA:
			return {
				...state,
				[payload.type]: {
					...state[payload.type],
					search: {
						...state.search,
						media: payload.media,
						query: payload.query,
					},
					thereAreMoreMedia: (payload.media.length < mediaDefaultLimit && payload.query.length > 0) ? false : true,
					bodyMultimediaFetch: {
						...state[payload.type].bodyMultimediaFetch,
						options: {
							...state[payload.type].bodyMultimediaFetch.options,
							index: payload.query.length === 0 ? state[payload.type].items.length : (state[payload.type].bodyMultimediaFetch.options.index + payload.media.length)
						}
					},
				}
			}

		case MultimediaTypes.GET_VIDEOS_BY_BRAND:
			return {
				...state,
				videosByBrand: payload
			}

		case MultimediaTypes.RESET_MEDIA:
			return {
				...state,
				image: {
					...state.image,
					items: [],
					bodyMultimediaFetch: {
						...state.image.bodyMultimediaFetch,
						options: {
							...state.image.bodyMultimediaFetch.options,
							index: 0
						}
					},
					thereAreMoreMedia: true
				},
				video: {
					...state.video,
					items: [],
					bodyMultimediaFetch: {
						...state.video.bodyMultimediaFetch,
						options: {
							...state.video.bodyMultimediaFetch.options,
							index: 0
						}
					},
					thereAreMoreMedia: true
				},
				banner: {
					...state.banner,
					items: [],
					bodyMultimediaFetch: {
						...state.banner.bodyMultimediaFetch,
						options: {
							...state.banner.bodyMultimediaFetch.options,
							index: 0
						}
					},
					thereAreMoreMedia: true
				}
			}

		default:
			return state
	}
}