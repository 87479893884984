/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer, useMemo, useEffect, useCallback, memo } from 'react'
import UserContext from './UserContext'
import initialUserState from './initialUserState'
import userReducer from 'contexts/user/reducer/user.reducer'
import * as action from "contexts/user/reducer/user.actions";
import { useAuth0 } from '@auth0/auth0-react';
import { DbUser } from 'types';
import { useTranslation } from 'react-i18next';
import { ISAdminClientState, ISAdminUserClientState } from 'interfaces/superAdmin.interface';
import { IInitialUserState, TSocialMedia, TUpdateCompanyProps, TUpdateLanguageProps } from 'interfaces/user.interface';
import { ChildrenProps } from 'interfaces/general.interface';
import useContextUILanguage from 'hooks/contexts/UI/useContextUILanguage';

function UserProvider(props: ChildrenProps) {
	const [uState, dispatch] = useReducer(userReducer, initialUserState)
	const userState = uState as IInitialUserState
	const { user, isAuthenticated, isLoading, getAccessTokenSilently, logout } = useAuth0()
	const { t: translate } = useTranslation()
	const { languages } = useContextUILanguage()

	useEffect(() => {
		if (isLoading && !isAuthenticated) return
		(async () => {
			const token = await getAccessTokenSilently()
			token && action.loginUserAction(dispatch, token, user, translate, logout)
		})()
	}, [isLoading, isAuthenticated])

	const login = useCallback(async () => {
		const token = await getAccessTokenSilently()
		token && action.loginUserAction(dispatch, token, user, translate, logout)
	}, [translate, userState.dbUser])

	const updateUser = useCallback(async (data: DbUser, setIsOpenDrawer: (bool: boolean) => void) => {
		const token = await getAccessTokenSilently()
		data && action.updateUserAction(dispatch, userState.dbUser, data, token, setIsOpenDrawer, translate)
	}, [translate, userState.dbUser])

	const updateCompanyData = useCallback(async (companyData: TUpdateCompanyProps, setIsOpenDrawer: (bool: boolean) => void) => {
		const token = await getAccessTokenSilently()
		companyData && action.updateCompanyAction(dispatch, userState, companyData, token, setIsOpenDrawer, translate)
	}, [userState, translate])

	const updateUserAvatar = useCallback(async (image: File) => {
		const token = await getAccessTokenSilently()
		image && action.updateUserAvatarAction(dispatch, userState.dbUser.id, image, token, translate)
	}, [userState, translate])

	const updateCompanyLogo = useCallback(async (image: File) => {
		const token = await getAccessTokenSilently()
		image && action.updateCompanyLogoAction(dispatch, userState.company.id, image, token, translate)
	}, [userState.company.id, translate])

	const updateLanguage = useCallback(async (language: TUpdateLanguageProps) => {
		const token = await getAccessTokenSilently()
		action.updateLanguageAction(dispatch, userState.dbUser, language, token, translate)
	}, [translate, userState.dbUser])

	const updateSocialMedia = useCallback(async (socialMedia: TSocialMedia, setShowConfirmEditBtn: any) => {
		const token = await getAccessTokenSilently()
		action.updateSocialMediaAction(dispatch, userState.company, socialMedia, setShowConfirmEditBtn, token, translate)
	}, [translate])

	const removeSocialMedia = useCallback(async (socialMediaId: string) => {
		const token = await getAccessTokenSilently()
		action.removeSocialMediaAction(dispatch, userState.company, socialMediaId, token, translate)
	}, [translate])

	const selectUserSuperAdmin = useCallback(async (client: ISAdminClientState, user: ISAdminUserClientState) => {
		const token = await getAccessTokenSilently()
		action.selectUserAndCompanySuperAdminAction(dispatch, client, user, languages, translate, token)
	}, [languages, translate])

	const selectCompanySuperAdmin = useCallback(async (client: ISAdminClientState) => {
		const token = await getAccessTokenSilently()
		action.selectCompanySuperAdminAction(dispatch, client, translate, token)
	}, [translate])

	const memoProvider = useMemo(
		() => ({
			...userState,
			login,
			updateUser,
			updateCompanyData,
			updateUserAvatar,
			updateCompanyLogo,
			updateLanguage,
			updateSocialMedia,
			removeSocialMedia,
			selectUserSuperAdmin,
			selectCompanySuperAdmin
		}), [
		userState,
		login,
		updateUser,
		updateCompanyData,
		updateUserAvatar,
		updateCompanyLogo,
		updateLanguage,
		updateSocialMedia,
		removeSocialMedia,
		selectUserSuperAdmin,
		selectCompanySuperAdmin
	]
	);

	return (
		<UserContext.Provider value={memoProvider}>
			{props.children}
		</UserContext.Provider>
	)
}

export default memo(UserProvider)