import { IBrandUser, IInitialBrandUsersState } from 'interfaces/brandUsers.interface';
import * as BrandUsersTypes from './brandUsers.types'

export default function brandUsersReducer(state: IInitialBrandUsersState, action: any) {
	const { type, payload } = action;

	switch (type) {
		case BrandUsersTypes.GET_BRAND_USERS:
			return {
				...state,
				brandUsers: payload
			}

		case BrandUsersTypes.SELECT_BRAND_USER:
			return {
				...state,
				brandUsersSelected: payload
			}

		case BrandUsersTypes.CREATE_BRAND_USER:
			return {
				...state,
				brandUsers: [...state.brandUsers, payload]
			}

		case BrandUsersTypes.UPDATE_BRAND_USER:
			return {
				...state,
				brandUsers: payload
			}

		case BrandUsersTypes.DELETE_BRAND_USER:
			return {
				...state,
				brandUsers: state.brandUsers.filter((user: IBrandUser) => user.id !== payload)
			}

		case BrandUsersTypes.SET_IS_LOADING_ACTION:
			return {
				...state,
				isLoadingAction: payload
			}

		case BrandUsersTypes.SET_IS_LOADING_USERS:
			return {
				...state,
				isLoadingUsers: payload
			}

		default:
			return state
	}
}