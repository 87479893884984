import { IBodyMultimediaFetch } from "interfaces/multimedia.interface"
import { basePath } from "./utils/config"

export const findMultimediaAPI = async (body: IBodyMultimediaFetch, signal: AbortSignal | undefined, token: string): Promise<any> => {
	const params = {
		signal: signal,
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body),
	}
	const response = await fetch(`${basePath}/multimedia/find_multimedia`, params)
	const data = await response.json()
	return { response, data }
}