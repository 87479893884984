import { IBrandsBodyFetchState, IBrandsBrandState, IInitialBrandsState } from "interfaces/brands.interface"

const initialBrandsState: IInitialBrandsState = {
	allBrands: [] as IBrandsBrandState[],
	// Pagination
	brandsWithPagination: [] as IBrandsBrandState[],
	bodyFetch: {
		search: "",
		index: 0,
		limit: 40
	} as IBrandsBodyFetchState,
	isLoading: true,
	isLoadingMoreBrands: true,
	thereAreMoreBrands: true,
	addMoreBrands: () => { },
	setBodySearch: (query: string) => { },
}

export default initialBrandsState