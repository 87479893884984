const initialMainContactsState = {
	contacts: [
		{
			key: '',
			name: '',
			lastname: '',
			email: '',
			phoneNumberPrefix: '',
			phoneNumberSufix: '',
			sector: ''
		}
	],
	contactSelected: {
		key: '',
		name: '',
		lastname: '',
		email: '',
		phoneNumberPrefix: '',
		phoneNumberSufix: '',
		sector: ''
	},
	getMainContacts: () => { },
	selectContact: (contact: any) => { },
	createContact: (contact: any, setIsOpenDrawer: () => void) => { },
	removeContact: (contactId: any) => { },
	updateContact: (newContact: any, setIsOpenDrawer: () => void) => { }
}

export default initialMainContactsState