import { IBodyProductSearch, IBodyProductSearchTemp, ICatalogFetchDataResponse, ICatalogItemsState, ICatalogState, IFavorite, IProductFetchDataResponse, TQuerySearchArray } from "interfaces/catalog.interface"

const catalogState: ICatalogItemsState = {
	count: 1,
	data: [] as ICatalogFetchDataResponse[]
}

const relatedProductsState: ICatalogItemsState = {
	count: 1,
	data: [] as ICatalogFetchDataResponse[]
}

const productState: IProductFetchDataResponse = {
	product_id: "",
	product_description: "",
	ranking: 0,
	units: 0,
	brand_id: "",
	brand_name: "",
	brand_logo: "",
	color_id: "",
	color_code: "",
	reference_id: "",
	reference_code: "",
	material: '',
	tags: [""],
	images: [{
		id: "",
		url: ""
	}],
	ean_size: [
		{
			ean: "",
			size: ""
		},
	],
	favorite: [] as IFavorite[],
	season: "",
	segmentation: "",
	family: "",
	division: "",
	gender: "",
	color: "",
	sizes: [""],
	prices: [{
		ean: "",
		size: "",
		pvi: 0,
		pvpr: 0
	}],
	other_colors: [{
		id: '',
		color: '',
		colorRef: '',
		image: '',
	}]
}


const bodyProductSearchTemp: IBodyProductSearchTemp = {
	options: {
		brand_id: [''],
		client_id: "",
		csv_format: false,
		favorite: "",
		with_images: null,
		search: [],
		language_id: "",
		order: [],
		group: [],
		limit: 30,
		index: 0
	},
	filter: {
		reference: [],
		color: [],
		season: [],
		segmentation: [],
		division: [],
		family: [],
		gender: [],
		pvi: [],
		pvpr: [],
		tag: [],
		tier: [],
	}
}
const bodyProductSearch: IBodyProductSearch = JSON.parse(JSON.stringify(bodyProductSearchTemp));

const initialCatalogState: ICatalogState = {
	catalogItems: catalogState,
	relatedProducts: relatedProductsState,
	productItem: productState,
	isLoading: true,
	isLoadingRelatedProducts: true,
	isLoadingMoreProducts: false,
	isLoadingProduct: true,
	bodyProductSearch: bodyProductSearch,
	bodyProductSearchTemp: bodyProductSearchTemp,
	querysearchArray: [] as TQuerySearchArray[],
	thereAreMoreProducts: true,
	isEmptyProducts: false,
	brandSelected: {
		id: "",
		name: "",
		logo: ""
	},
	filtersLengths: {
		brand: 0,
		color: 0,
		gender: 0,
		division: 0,
		family: 0,
		season: 0
	},
	filtersLengthsTemp: {
		brand: 0,
		color: 0,
		gender: 0,
		division: 0,
		family: 0,
		season: 0
	},
	querySearch: "",
	setQuerySearch: () => { },
	addToBodySearch: () => { },
	removeFromBodySearch: () => { },
	removeAllFromBodySearch: () => { },
	removeFromBodySearchFilter: () => { },
	setSortBy: () => { },
	// getCatalog: () => { },
	addMoreProductsToCatalog: () => { },
	filter: () => { },
	addBodyFilters: () => { },
	removeBodyFilters: () => { },
	resetTempBodyFilters: () => { },
	addBodyBrandOptions: () => { },
	removeBodyBrandOptions: () => { },
	resetPriceSlider: () => { },
	addToQuerySearchArray: () => { },
	removeFromQuerySearchArray: () => { },
	resetBodyFilters: () => { },
	setPriceSliders: () => { },
	getProduct: () => { },
	getProductsByBrands: () => { },
	setFavorites: () => { },
	addLike: () => { },
	removeLike: () => { },
	addLikeToProduct: () => { },
	removeLikeToProduct: () => { },
	addLikeToRelatedProduct: () => { },
	removeLikeToRelatedProduct: () => { },
	setProductBrandId: () => { },
	getRelatedProducts: () => { },
	resetCatalogItems: () => { },
}

export default initialCatalogState