import { memo, useState } from "react"
import { DrawersMultimediaContext } from "./DrawersMultimediaContext"
import { ChildrenProps } from "interfaces/general.interface"

const DrawersMultimediaProvider = ({ children }: ChildrenProps) => {
	const [isOpenImagesDrawer, setIsOpenImagesDrawer] = useState(false)
	const [isOpenVideosDrawer, setIsOpenVideosDrawer] = useState(false)
	const [isOpenBannersDrawer, setIsOpenBannersDrawer] = useState(false)

	const setIsOpenImagesDrawerAction = (isOpen: boolean) => {
		setIsOpenImagesDrawer(isOpen)
	}

	const setIsOpenVideosDrawerAction = (isOpen: boolean) => {
		setIsOpenVideosDrawer(isOpen)
	}

	const setIsOpenBannersDrawerAction = (isOpen: boolean) => {
		setIsOpenBannersDrawer(isOpen)
	}

	return (
		<DrawersMultimediaContext.Provider value={{
			isOpenImagesDrawer,
			isOpenVideosDrawer,
			isOpenBannersDrawer,
			setIsOpenImagesDrawerAction,
			setIsOpenVideosDrawerAction,
			setIsOpenBannersDrawerAction
		}}>
			{children}
		</DrawersMultimediaContext.Provider>
	)
}

export default memo(DrawersMultimediaProvider)