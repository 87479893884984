/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer, useMemo, useCallback, memo, useEffect } from 'react'
import MyBrandsContext from './MyBrandsContext'
import initialMyBrandsState from './initialMyBrandsState'
import userReducer from 'contexts/myBrands/reducer/myBrands.reducer'
import * as action from "contexts/myBrands/reducer/myBrands.actions";
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { IinitialMyBrandsState, TCreateNewBrandProps } from 'interfaces/myBrands.interface';
import useContextUser from 'hooks/contexts/useContextUser';
import { IBrandsBrandState } from 'interfaces/brands.interface';
import { ChildrenProps } from 'interfaces/general.interface';
import { TBannerItemResponse } from 'api/general.api';

function MyBrandsProvider(props: ChildrenProps) {
	const [mbs, dispatch] = useReducer(userReducer, initialMyBrandsState)
	const myBrandsState = mbs as IinitialMyBrandsState
	const { getAccessTokenSilently, isAuthenticated } = useAuth0()
	const { t: translate } = useTranslation()
	const { dbUser, company } = useContextUser()
	const { name } = dbUser
	const country_id = company.country?.id

	useEffect(() => {
		(async () => {
			if (company.id && isAuthenticated && dbUser.associationsStates && country_id) {
				const token = await getAccessTokenSilently()
				action.getMyBrandsAction(dispatch, company.id, token, translate, dbUser.associationsStates, country_id)
				return
			}
		})()
	}, [company.id, country_id, dbUser.associationsStates, isAuthenticated, translate])

	const getMyBrands = useCallback(async () => {
		const token = await getAccessTokenSilently()
		action.getMyBrandsAction(dispatch, company.id, token, translate, dbUser.associationsStates, country_id)
	}, [company.id, country_id, dbUser.associationsStates, translate])

	const addToMyBrand = useCallback(async ({ brand, banner, setIsOpenDrawer }: { brand?: IBrandsBrandState, banner?: TBannerItemResponse, setIsOpenDrawer?: any }) => {
		const token = await getAccessTokenSilently()
		action.addToMyBrandAction(dispatch, brand ?? null, banner ?? null, company.id, token, translate, setIsOpenDrawer, dbUser.associationsStates, country_id)
	}, [company.id, country_id, dbUser.associationsStates, translate])

	const addToMyBrandMultiple = useCallback(async ({ brands }: { brands: IBrandsBrandState[] }) => {
		const token = await getAccessTokenSilently()
		action.addToMyBrandMultipleAction(dispatch, myBrandsState.myBrands, brands, company.id, token, translate, dbUser.associationsStates, country_id)
	}, [company.id, country_id, dbUser.associationsStates, myBrandsState.myBrands, translate])

	const removeFromMyBrand = useCallback(async ({ associationId }: { associationId: string }) => {
		const token = await getAccessTokenSilently()
		action.removeFromMyBrandAction(dispatch, associationId, myBrandsState.myBrands, dbUser.associationsStates, token, translate)
	}, [myBrandsState.myBrands, dbUser.associationsStates, translate])

	const removeFromMyBrandsMultiple = useCallback(async ({ associationsIds }: { associationsIds: string[] }) => {
		const token = await getAccessTokenSilently()
		return action.removeFromMyBrandsMultipleAction(dispatch, associationsIds, myBrandsState.myBrands, token, translate) as Promise<boolean>
	}, [myBrandsState.myBrands, translate])

	const createNewBrand = useCallback(async ({brand, formReset, setIsOpenDrawer}: { brand: TCreateNewBrandProps, formReset: any, setIsOpenDrawer: (value: boolean) => void }) => {
		const token = await getAccessTokenSilently()
		action.createNewBrandAction(dispatch, brand, company.id, name, token, translate, dbUser.associationsStates, setIsOpenDrawer, formReset)
	}, [company.id, dbUser.associationsStates, name, translate])

	const memoProvider = useMemo(
		() => ({
			...myBrandsState,
			getMyBrands,
			removeFromMyBrand,
			removeFromMyBrandsMultiple,
			addToMyBrand,
			createNewBrand,
			addToMyBrandMultiple
		}), [
		myBrandsState,
		getMyBrands,
		removeFromMyBrand,
		removeFromMyBrandsMultiple,
		addToMyBrand,
		createNewBrand,
		addToMyBrandMultiple
	]
	);

	return (
		<MyBrandsContext.Provider value={memoProvider}>
			{props.children}
		</MyBrandsContext.Provider>
	)
}

export default memo(MyBrandsProvider)