import { message } from "antd"

const makeConfig = () => {
  return message.config({
    top: 35,
    maxCount: 3,
  })
}

export const messageSuccess = (msg: string, duration: number = 3) => {
  makeConfig()
  return message.success(msg, duration)
}

export const messageInfo = (msg: string, duration: number = 3) => {
  makeConfig()
  return message.info(msg, duration)
}

export const messageWarning = (msg: string, duration: number = 3) => {
  makeConfig()
  return message.warning(msg, duration)
}

export const messageError = (msg: string, duration: number = 5) => {
  makeConfig()
  return message.error(msg, duration)
}

export const messageLoading = (msg: string, duration: number = 1) => {
  makeConfig()
  return message.loading(msg, duration)
}