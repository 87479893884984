import { TResponseApi } from "interfaces/general.interface"
import { basePath } from "./utils/config"
import { IgetPointOfSaleResponseAPI, TGetPointOfSaleCategoriesAPI, TremovePointOfSaleAPIResponse } from "interfaces/pointsOfSale.interface"

export const getPointsOfSaleAPI = async (clientId: string, token: string): Promise<TResponseApi & IgetPointOfSaleResponseAPI> => {
	const params = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	}
	const response = await fetch(`${basePath}/client_profile/get_client_points_of_sale?client_id=${clientId}`, params)
	const data = await response.json()
	return { response, data }
}

export const createPointsOfSaleAPI = async (pointOfSale: any, token: string): Promise<any> => {
	const params = {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(pointOfSale),
	}
	const response = await fetch(`${basePath}/point_of_sale_profile/create_point_of_sale`, params)
	const data = await response.json()
	return { response, data }
}

export const updatePointOfSaleAPI = async (body: any, token: string): Promise<any> => {
	const params = {
		method: "PATCH",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body),
	}
	const response = await fetch(`${basePath}/point_of_sale_profile/update_point_of_sale`, params)
	const data = await response.json()
	return { response, data }
}

export const removePointOfSaleAPI = async (pointOfSaleId: string, token: string): Promise<TResponseApi & TremovePointOfSaleAPIResponse> => {
	const params = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	}
	const response = await fetch(`${basePath}/point_of_sale_profile/delete_point_of_sale?point_of_sale_id=${pointOfSaleId}`, params)
	const data = await response.json()
	return { response, data }
}

export const getPointsOfSaleCategoriesAPI = async (token: string): Promise<TResponseApi & TGetPointOfSaleCategoriesAPI> => {
	const params = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	}
	const response = await fetch(`${basePath}/point_of_sale_profile/get_point_of_sale_category`, params)
	const data = await response.json()
	return { response, data }
}

export const getExchangesAPI = async (token: string): Promise<any> => {
	const params = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	}
	const response = await fetch(`${basePath}/point_of_sale_profile/get_exchange_list`, params)
	const data = await response.json()
	return { response, data }
}