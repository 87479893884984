import { IinitialBrandFeedState } from '../initialBrandFeedState';
import * as BrandFeedTypes from './brandFeed.types'

export default function brandFeedReducer(state: IinitialBrandFeedState, action: any) {
	const { type, payload } = action;

	switch (type) {
		case BrandFeedTypes.GET_BRAND_FEED:
			return {
				...state,
				brand: payload
			}

		default:
			return state
	}
}