/* eslint-disable eqeqeq */
import { showMoreProducts } from 'utils/constants'
import * as CatalogTypes from './catalog.types'
import * as catalogAPI from 'api/catalog.api'
import { catalogError } from 'utils/errorCodes'
import { notificationErr } from 'views/components/UI/notification'
import { IBodyProductSearch, IBodyProductSearchTemp, ICatalogFetchDataResponse, ICatalogItemsState, ICatalogState, IFavorite, IProductFetchDataResponse, TQuerySearchArray } from 'interfaces/catalog.interface'
import { IMyBrand } from 'interfaces/myBrands.interface'

export async function getCatalogAction(
	dispatch: any,
	bodyProductSearchTemp: IBodyProductSearchTemp,
	translate: any,
	signal: AbortSignal | undefined,
	token: string,
) {
	dispatch({ type: CatalogTypes.SET_IS_LOADING, payload: true })
	try {
		const products = await getAndOrderImagesCatalog(dispatch, bodyProductSearchTemp, signal, token)
		if (products) {
			dispatch({
				type: CatalogTypes.GET_CATALOG,
				payload: products
			})
			const bodyProductSearchTempCopy: IBodyProductSearchTemp = JSON.parse(JSON.stringify(bodyProductSearchTemp))
			bodyProductSearchTempCopy.options.index = bodyProductSearchTempCopy.options.index + showMoreProducts
			dispatch({ type: CatalogTypes.SET_BODY_PRODUCT_SEARCH_TEMP, payload: bodyProductSearchTempCopy })
			return dispatch({ type: CatalogTypes.SET_BODY_PRODUCT_SEARCH, payload: bodyProductSearchTempCopy })
		}
	} catch (err) {
		// return notificationErr(catalogError.get_catalog_001, translate)
	} finally {
		dispatch({ type: CatalogTypes.SET_IS_LOADING, payload: false })
	}
}

export async function getRelatedProductsAction(
	dispatch: any,
	clientId: string,
	bodyProductSearchTemp: IBodyProductSearchTemp,
	translate: any,
	token: string,
) {
	const bodyProductSearchTempCopy: IBodyProductSearchTemp = JSON.parse(JSON.stringify(bodyProductSearchTemp))
	dispatch({ type: CatalogTypes.SET_IS_LOADING_RELATED_PRODUCTS, payload: true })
	try {
		let body: IBodyProductSearch = {
			options: {
				brand_id: bodyProductSearchTempCopy.options.brand_id,
				client_id: clientId,
				csv_format: false,
				favorite: "",
				with_images: null,
				search: [],
				language_id: bodyProductSearchTempCopy.options.language_id,
				order: [],
				group: [],
				limit: 20,
				index: 40
			},
			filter: {
				reference: [],
				color: [],
				season: [],
				segmentation: [],
				division: [],
				family: [],
				gender: [],
				pvi: [],
				pvpr: [],
				tag: [],
				tier: [],
			}
		}
		bodyProductSearchTempCopy.options.index = bodyProductSearchTempCopy.options.index - 10
		if (Math.sign(bodyProductSearchTempCopy.options.index) === -1) {
			bodyProductSearchTempCopy.options.index = 0
		}
		const products = await getAndOrderImagesCatalog(dispatch, bodyProductSearchTempCopy, undefined, token)
		if (products) {
			if (products.data.length < 20) {
				body.options.index = 0
				const moreProducts = await getAndOrderImagesCatalog(dispatch, body, undefined, token)
				const allProducts = products.data.concat(moreProducts.data)
				return dispatch({
					type: CatalogTypes.SET_RELATED_PRODUCTS,
					payload: {
						data: allProducts,
						count: allProducts.length
					}
				})
			}
			return dispatch({
				type: CatalogTypes.SET_RELATED_PRODUCTS,
				payload: products
			})
		}
	} catch (err) {
		return notificationErr(catalogError.get_catalog_001, translate)
	} finally {
		dispatch({ type: CatalogTypes.SET_IS_LOADING_RELATED_PRODUCTS, payload: false })
	}
}

export async function addMoreProductsToCatalogAction(
	dispatch: any,
	bodyProductSearchTemp: IBodyProductSearchTemp,
	translate: any,
	token: string
) {
	const bodyProductSearchTempCopy: IBodyProductSearchTemp = JSON.parse(JSON.stringify(bodyProductSearchTemp))
	if (bodyProductSearchTempCopy.options.index === 0) {
		bodyProductSearchTempCopy.options.index = 10
	}
	dispatch({ type: CatalogTypes.SET_IS_LOADING_MORE_PRODUCTS, payload: true })
	dispatch({ type: CatalogTypes.INCREMENT_PAGINATION_INDEX, payload: bodyProductSearchTempCopy.options.index + showMoreProducts })
	try {
		const products = await getAndOrderImagesCatalog(dispatch, bodyProductSearchTempCopy, undefined, token)
		if (products) {
			return dispatch({
				type: CatalogTypes.ADD_MORE_TO_CATALOG,
				payload: products
			})
		}
	} catch (err) {
		// return notificationErr(catalogError.add_more_products_to_catalog_001, translate)
	} finally {
		dispatch({ type: CatalogTypes.SET_IS_LOADING_MORE_PRODUCTS, payload: false })
	}
}

export async function addToBodySearchAction(
	dispatch: any,
	query: string,
	bodyProductSearchTemp: IBodyProductSearchTemp,
	translate: any,
	signal: AbortSignal | undefined,
	token: string,
) {
	try {
		const bodyProductSearchTempCopy: IBodyProductSearchTemp = JSON.parse(JSON.stringify(bodyProductSearchTemp))
		bodyProductSearchTempCopy.options.index = 0
		bodyProductSearchTempCopy.options.search.push(`%${query}%`)
		dispatch({ type: CatalogTypes.SET_BODY_PRODUCT_SEARCH, payload: bodyProductSearchTempCopy })
		dispatch({ type: CatalogTypes.SET_BODY_PRODUCT_SEARCH_TEMP, payload: bodyProductSearchTempCopy })
		return await getCatalogAction(dispatch, bodyProductSearchTempCopy, translate, signal, token)
	} catch (err) {
		console.log(err);
	}
}

export async function removeFromBodySearchAction(
	dispatch: any,
	query: string,
	bodyProductSearchTemp: IBodyProductSearchTemp,
	translate: Function,
	signal: AbortSignal | undefined,
	token: string,
) {
	try {
		const bodyProductSearchTempCopy: IBodyProductSearchTemp = JSON.parse(JSON.stringify(bodyProductSearchTemp))
		bodyProductSearchTempCopy.options.index = 0
		const filteredSearch = bodyProductSearchTempCopy.options.search.filter((item: any) => item !== query)
		bodyProductSearchTempCopy.options.search = filteredSearch
		dispatch({ type: CatalogTypes.SET_BODY_PRODUCT_SEARCH, payload: bodyProductSearchTempCopy })
		dispatch({ type: CatalogTypes.SET_BODY_PRODUCT_SEARCH_TEMP, payload: bodyProductSearchTempCopy })
		return await getCatalogAction(dispatch, bodyProductSearchTempCopy, translate, signal, token)
	} catch (err) {
		console.log(err);
	}
}

export async function removeAllFromBodySearchAction(
	dispatch: any,
	catalogState: ICatalogState,
	translate: Function,
	signal: AbortSignal | undefined,
	token: string,
) {
	try {
		const catalogStateCopy: ICatalogState = JSON.parse(JSON.stringify(catalogState))
		catalogStateCopy.bodyProductSearchTemp.options.index = 0
		catalogStateCopy.bodyProductSearchTemp.options.search = []
		catalogStateCopy.bodyProductSearchTemp.options.favorite = ""
		catalogStateCopy.bodyProductSearchTemp.filter = {
			reference: [],
			color: [],
			season: [],
			segmentation: [],
			division: [],
			family: [],
			gender: [],
			pvi: [],
			pvpr: [],
			tag: [],
			tier: [],
		}
		catalogStateCopy.filtersLengthsTemp = {
			brand: catalogStateCopy.filtersLengths.brand,
			color: 0,
			gender: 0,
			division: 0,
			family: 0,
			season: 0
		}
		dispatch({ type: CatalogTypes.SET_QUERY_SEARCH_ARRAY, payload: [] })
		dispatch({ type: CatalogTypes.SET_FILTERS_LENGTHS, payload: catalogStateCopy.filtersLengthsTemp })
		dispatch({ type: CatalogTypes.SET_BODY_PRODUCT_SEARCH_TEMP, payload: catalogStateCopy.bodyProductSearchTemp })
		return await getCatalogAction(dispatch, catalogStateCopy.bodyProductSearchTemp, translate, signal, token)
	} catch (err) {
		console.log(err);
	}
}

export async function removeFromBodySearchFilterAction(
	dispatch: any,
	value: TQuerySearchArray,
	catalogState: ICatalogState,
	translate: Function,
	signal: AbortSignal | undefined,
	token: string,
) {
	const { values, field } = value as TQuerySearchArray
	try {
		const catalogStateCopy: ICatalogState = JSON.parse(JSON.stringify(catalogState))
		catalogStateCopy.bodyProductSearchTemp.options.index = 0
		values.forEach((id: string) => {
			catalogStateCopy.bodyProductSearchTemp.filter[field as keyof typeof catalogStateCopy.bodyProductSearchTemp.filter] = catalogStateCopy.bodyProductSearchTemp.filter[field as keyof typeof catalogStateCopy.bodyProductSearchTemp.filter].filter((item: any) => item !== id)
		});
		dispatch({
			type: CatalogTypes.SET_BODY_SEARCH_FILTER, payload: {
				querySearchArray: catalogStateCopy.querysearchArray,
				field: field,
				value: value
			}
		})
		dispatch({ type: CatalogTypes.SET_BODY_PRODUCT_SEARCH, payload: catalogStateCopy.bodyProductSearchTemp })
		dispatch({ type: CatalogTypes.SET_BODY_PRODUCT_SEARCH_TEMP, payload: catalogStateCopy.bodyProductSearchTemp })
		return await getCatalogAction(dispatch, catalogStateCopy.bodyProductSearchTemp, translate, signal, token)
	} catch (err) {
		console.log(err);
	}
}

export const sizeOrder = {
	'XXS': 0,
	'XS': 1,
	'S': 2,
	'M': 3,
	'L': 4,
	'XL': 5,
	'XXL': 6,
	'3XL': 7,
	'4XL': 8,
	//***** */
	'0': 16,
	'1': 17,
	'1.5': 18,
	'2': 19,
	'2.5': 20,
	'3': 21,
	'3.5': 22,
	'4': 23,
	'4.5': 24,
	'5': 25,
	'5.5': 26,
	'6': 27,
	'6.5': 28,
	'7': 29,
	'7.5': 30,
	'8': 31,
	'8.5': 32,
	'9': 33,
	'9.5': 34,
	'10': 35,
	'10.5': 36,
	'11': 37,
	'11.5': 38,
	'12': 39,
	'12.5': 40,
	'13': 41,
	'13.5': 42,
	'14': 43,
	'14.5': 44,
	'15': 45,
	//***** */
	'24': 53,
	'25': 53.5,
	'26': 54,
	'27': 54.5,
	'28': 55,
	'29': 55.5,
	'30': 56,
	'31': 56.5,
	'32': 57,
	'33': 57.5,
	'34': 58,
	'35': 58.5,
	'36': 59,
	'37': 59.5,
	'38': 60,
	'39': 60.5,
	'40': 61,
	'41': 61.5,
	'42': 62,
	'43': 62.5,
	'44': 63,
	'45': 63.5,
	'46': 64,
	'47': 64.5,
	'48': 65,
	'49': 65.5,
	'50': 66,
	'51': 66.5,
	'52': 67,
	'53': 67.5,
	'54': 68,
	'55': 68.5,
	'56': 69,
	'57': 69.5,
	'58': 70,
	'59': 70.5,
	'60': 71,
	//***** */
	'80': 82,
	'90': 83,
	'100': 84,
	'110': 85,
	'120': 86,
	'130': 87,
	'140': 88,
	'150': 89,
	//***** */
	'6M': 100,
	'12M': 101,
	//***** */
	'1YR': 109,
	'2YR': 110,
	'3YR': 111,
	'4YR': 112,
	'5YR': 113,
	'6YR': 114,
	'7YR': 115,
	'8YR': 116,
	'9YR': 117,
	'10YR': 118,
	'11YR': 119,
	'12YR': 120,
	'13YR': 121,
	'14YR': 122,
	'15YR': 123,
	'16YR': 124,
	'17YR': 125,
};

export async function getProductAction(dispatch: any, productId: string, brandId: string, languageId: string, userId: string, token: any) {
	dispatch({ type: CatalogTypes.SET_IS_LOADING_PRODUCT, payload: true })
	try {
		const getProductFetch = await catalogAPI.getProductAPI(productId, brandId, languageId, userId, token)
		const {
			product_id,
			product_description,
			ranking,
			units,
			brand_id,
			brand_name,
			brand_logo,
			color,
			color_id,
			color_code,
			reference_id,
			reference_code,
			material,
			tags,
			images,
			ean_size,
			favorite,
			season,
			segmentation,
			family,
			division,
			gender,
			sizes,
			prices,
			other_colors,
		} = getProductFetch.data

		// Sort by las two digits before the image url extension
		const sortedUrls = images?.sort((a: { id: string, url: string }, b: { id: string, url: string }) => {
			const matchA = a.url.match(/(\d{2})\.\w+$/);
			const matchB = b.url.match(/(\d{2})\.\w+$/);

			if (!matchA) return 1;
			if (!matchB) return -1;

			const numA = parseInt(matchA[1], 10);
			const numB = parseInt(matchB[1], 10);

			// Priorizar la imagen que termina en 1
			if (numA % 10 === 1 && numB % 10 !== 1) return -1;
			if (numB % 10 === 1 && numA % 10 !== 1) return 1;

			// Si ambos terminan en 1, ordenar de menor a mayor
			if (numA % 10 === 1 && numB % 10 === 1) return numA - numB;

			return numA - numB;
		});

		if (getProductFetch.response.status === 200) {
			const productState: IProductFetchDataResponse = {
				product_id: product_id,
				product_description: product_description,
				ranking: ranking,
				units: units,
				brand_id: brand_id,
				brand_name: brand_name,
				brand_logo: brand_logo,
				color_id: color_id,
				color_code: color_code,
				reference_id: reference_id,
				reference_code: reference_code,
				material: material,
				tags: tags,
				images: sortedUrls ?? [],
				ean_size: ean_size.sort((a, b) => ((sizeOrder as any)[a.size] || Infinity) - ((sizeOrder as any)[b.size] || Infinity)),
				favorite: favorite,
				season: season,
				segmentation: segmentation,
				family: family,
				division: division,
				gender: gender,
				color: color,
				sizes: sizes.sort((a, b) => ((sizeOrder as any)[a] || Infinity) - ((sizeOrder as any)[b] || Infinity)),
				prices: prices.sort((a, b) => ((sizeOrder as any)[a.size] || Infinity) - ((sizeOrder as any)[b.size] || Infinity)),
				other_colors: other_colors.map((item: any) => {
					return {
						id: item.id,
						color: item.color_name,
						colorRef: item.color,
						image: item.url,
					}
				})
			}
			return dispatch({
				type: CatalogTypes.GER_PRODUCT,
				payload: productState
			})
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch({ type: CatalogTypes.SET_IS_LOADING_PRODUCT, payload: false })
	}
}

export async function filterAction(dispatch: any, setOpenDrawer: any, bodyProductSearchTemp: IBodyProductSearchTemp, connectedBrands: IMyBrand[], translate: any, signal: AbortSignal | undefined, token: string) {
	const bodyProductSearchTempCopy: IBodyProductSearchTemp = JSON.parse(JSON.stringify(bodyProductSearchTemp))
	dispatch({ type: CatalogTypes.INCREMENT_PAGINATION_INDEX, payload: 0 })
	bodyProductSearchTempCopy.options.index = 0
	setOpenDrawer(false)
	try {
		await getCatalogAction(dispatch, bodyProductSearchTempCopy, translate, signal, token)
		if (bodyProductSearchTempCopy.options.brand_id.length === 1) {
			const findBrand = connectedBrands.find((brand) => brand.brand.id === bodyProductSearchTempCopy.options.brand_id[0])
			findBrand && dispatch({ type: CatalogTypes.SET_BRAND_SELECTED, payload: findBrand.brand })
			return
		} else {
			return dispatch({
				type: CatalogTypes.SET_BRAND_SELECTED,
				payload: {
					id: "",
					name: "",
					logo: ""
				}
			})
		}
	} catch (err) {
		return notificationErr(catalogError.filter_catalog_001, translate)
	}
}

export async function setQuerySearchAction(dispatch: any, query: string) {
	return dispatch({ type: CatalogTypes.SET_QUERY_SEARCH, payload: query })
}

export async function addBodyFiltersAction(dispatch: any, field: string, value: string[], bodyProductSearchTemp: IBodyProductSearch) {
	const bodyProductSearchTempCopy: IBodyProductSearchTemp = JSON.parse(JSON.stringify(bodyProductSearchTemp))
	value.forEach((id: string) => {
		bodyProductSearchTempCopy.filter[field as keyof typeof bodyProductSearchTempCopy.filter].push(id)
	});
	return dispatch({
		type: CatalogTypes.ADD_BODY_FILTERS,
		payload: {
			bodyProductSearchTemp: bodyProductSearchTempCopy,
			field: field
		}
	})
}

export async function removeBodyFiltersAction(dispatch: any, field: string, value: string[], bodyProductSearchTemp: IBodyProductSearch) {
	const bodyProductSearchTempCopy: IBodyProductSearchTemp = JSON.parse(JSON.stringify(bodyProductSearchTemp))
	value.forEach((id: string) => {
		bodyProductSearchTempCopy.filter[field as keyof typeof bodyProductSearchTempCopy.filter].forEach((item, index: number) => {
			if (item === id) {
				bodyProductSearchTempCopy.filter[field as keyof typeof bodyProductSearchTempCopy.filter].splice(index, 1)
			}
		})
	});
	return dispatch({
		type: CatalogTypes.REMOVE_BODY_FILTERS,
		payload: {
			bodyProductSearchTemp: bodyProductSearchTempCopy,
			field: field
		}
	})
}

export async function addBodyBrandOptionsAction(dispatch: any, value: string[], bodyProductSearchTemp: IBodyProductSearchTemp) {
	const bodyProductSearchTempCopy: IBodyProductSearchTemp = JSON.parse(JSON.stringify(bodyProductSearchTemp))
	value.forEach((id: string) => {
		bodyProductSearchTempCopy.options.brand_id.push(id)
	});
	return dispatch({
		type: CatalogTypes.ADD_BODY_BRAND_OPTIONS,
		payload: {
			bodyProductSearchTemp: bodyProductSearchTempCopy,
		}
	})
}

export async function removeBodyBrandOptionsAction(dispatch: any, value: string[], bodyProductSearchTemp: IBodyProductSearchTemp) {
	const bodyProductSearchTempCopy: IBodyProductSearchTemp = JSON.parse(JSON.stringify(bodyProductSearchTemp))
	value.forEach((id: string) => {
		bodyProductSearchTemp.options.brand_id.forEach((item, index: number) => {
			if (item === id) {
				bodyProductSearchTempCopy.options.brand_id.splice(index, 1)
			}
		})
	});
	return dispatch({
		type: CatalogTypes.REMOVE_BODY_BRAND_OPTIONS,
		payload: {
			bodyProductSearchTemp: bodyProductSearchTempCopy,
		}
	})
}

export async function setPriceSlidersAction(dispatch: any, field: string, value: string) {
	return dispatch({
		type: CatalogTypes.SET_PRICE_SLIDERS,
		payload: {
			field,
			value,
		}
	})
}

export async function resetPriceSliderAction(dispatch: any, type: string, bodyProductSearchTemp: IBodyProductSearchTemp, translate: any, signal: AbortSignal | undefined, token: string) {
	dispatch({ type: CatalogTypes.RESET_PRICE_SLIDER, payload: { type: type } })
	const bodyProductSearchTempCopy: IBodyProductSearchTemp = JSON.parse(JSON.stringify(bodyProductSearchTemp))
	bodyProductSearchTempCopy.filter[type as keyof typeof bodyProductSearchTemp.filter] = []
	return await getCatalogAction(dispatch, bodyProductSearchTempCopy, translate, signal, token)
}

export async function setSortByAction(dispatch: any, sortBy: string, bodyProductSearchTemp: IBodyProductSearchTemp, translate: any, signal: AbortSignal | undefined, token: string) {
	try {
		const bodyProductSearchTempCopy: IBodyProductSearchTemp = JSON.parse(JSON.stringify(bodyProductSearchTemp))
		bodyProductSearchTempCopy.options.order = [sortBy]
		bodyProductSearchTempCopy.options.index = 0
		dispatch({ type: CatalogTypes.SET_BODY_PRODUCT_SEARCH, payload: bodyProductSearchTempCopy })
		dispatch({ type: CatalogTypes.SET_BODY_PRODUCT_SEARCH_TEMP, payload: bodyProductSearchTempCopy })
		dispatch({ type: CatalogTypes.SET_SORT_BY, payload: sortBy })
		return await getCatalogAction(dispatch, bodyProductSearchTempCopy, translate, signal, token)
	} catch (error) {
		return notificationErr(catalogError.sort_by_001, translate)
	}
}

export async function getProductsByBrandsAction(dispatch: any, connectedBrands: IMyBrand[], brands: any[], translate: any, bodyProductSearchTemp: IBodyProductSearchTemp, signal: AbortSignal | undefined, token: string) {
	if (brands.length === 1) {
		dispatch({ type: CatalogTypes.SET_BRAND_SELECTED, payload: brands[0].brand })
	} else {
		dispatch({
			type: CatalogTypes.SET_BRAND_SELECTED,
			payload: {
				id: "",
				name: "",
				logo: ""
			}
		})
	}
	try {
		const bodyProductSearchTempCopy: IBodyProductSearchTemp = JSON.parse(JSON.stringify(bodyProductSearchTemp))
		const tenants: IMyBrand[] = brands.length === 0 ? connectedBrands : brands
		const tenantsId = tenants.map((item) => item.brand.id)
		bodyProductSearchTempCopy.options.brand_id = tenantsId
		bodyProductSearchTempCopy.options.index = 0
		console.log(bodyProductSearchTempCopy)
		dispatch({ type: CatalogTypes.SET_BODY_PRODUCT_SEARCH_TEMP, payload: bodyProductSearchTempCopy })
		getCatalogAction(dispatch, bodyProductSearchTempCopy, translate, signal, token)
	} catch (error) {
		return notificationErr(catalogError.sort_by_brand_001, translate)
	}
}

export async function resetBodyFiltersAction(dispatch: any, bodyProductSearchTemp: IBodyProductSearchTemp, setOpenDrawer: any, translate: any, signal: AbortSignal | undefined, token: string) {
	const bodyProductSearchTempCopy: IBodyProductSearchTemp = JSON.parse(JSON.stringify(bodyProductSearchTemp))
	setOpenDrawer(false)
	bodyProductSearchTempCopy.filter.season = []
	bodyProductSearchTempCopy.filter.color = []
	bodyProductSearchTempCopy.filter.segmentation = []
	bodyProductSearchTempCopy.filter.division = []
	bodyProductSearchTempCopy.filter.family = []
	bodyProductSearchTempCopy.filter.gender = []
	bodyProductSearchTempCopy.filter.pvi = []
	bodyProductSearchTempCopy.filter.pvpr = []
	bodyProductSearchTempCopy.filter.tag = []
	bodyProductSearchTempCopy.filter.tier = []
	bodyProductSearchTempCopy.options.favorite = ""
	dispatch({ type: CatalogTypes.SET_BODY_PRODUCT_SEARCH, payload: bodyProductSearchTempCopy })
	dispatch({ type: CatalogTypes.SET_BODY_PRODUCT_SEARCH_TEMP, payload: bodyProductSearchTempCopy })
	await getCatalogAction(dispatch, bodyProductSearchTempCopy, translate, signal, token)
	return dispatch({ type: CatalogTypes.RESET_BODY_FILTERS })
}

export async function setFavoritesAction(dispatch: any, userId: string) {
	dispatch({ type: CatalogTypes.INCREMENT_PAGINATION_INDEX, payload: 0 })
	return dispatch({ type: CatalogTypes.SET_FAVORITES, payload: userId })
}

export async function addLikeAction(dispatch: any, catalogItems: ICatalogItemsState, userId: string, productId: string, setHaveLike: any, token: string) {
	setHaveLike(true)
	try {
		const catalogItemsCopy: ICatalogItemsState = JSON.parse(JSON.stringify(catalogItems))
		const likeFetch = await catalogAPI.addLikeAPI(userId, productId, token)
		if (likeFetch.response.status === 200) {
			const findProduct = catalogItemsCopy.data.findIndex((item: any) => item.id === productId)
			const objectToPush = {
				favorite_id: likeFetch.data.id,
				user_id: userId,
			}
			catalogItemsCopy.data[findProduct].favorites.push(objectToPush)
			setHaveLike(true)
			return dispatch({
				type: CatalogTypes.GET_CATALOG,
				payload: catalogItemsCopy
			})
		} else {
			return setHaveLike(false)
		}
	} catch (err) {
		setHaveLike(false)
		console.log(err);
	}
}

export async function removeLikeAction(dispatch: any, catalogItems: ICatalogItemsState, productId: string, favorites: any, userId: string, setHaveLike: any, token: string) {
	const catalogItemsCopy: ICatalogItemsState = JSON.parse(JSON.stringify(catalogItems))
	for (const favorite of favorites) {
		if (favorite.user_id === userId) {
			setHaveLike(false)
			try {
				const likeFetch = await catalogAPI.removeLikeAPI(favorite.favorite_id, token)
				if (likeFetch.response.status === 200) {
					const findProduct = catalogItemsCopy.data.findIndex((item: any) => item.id === productId)
					const filteredFavorites = catalogItemsCopy.data[findProduct].favorites.filter((item: any) => item.favorite_id !== favorite.favorite_id)
					catalogItemsCopy.data[findProduct].favorites = filteredFavorites
					return dispatch({
						type: CatalogTypes.GET_CATALOG,
						payload: catalogItemsCopy
					})
				} else {
					return setHaveLike(true)
				}
			} catch (err) {
				setHaveLike(true)
				console.log(err);
			}
		}
	}
}

export async function addLikeToProductAction(dispatch: any, catalogItems: ICatalogItemsState, userId: string, productId: string, setHaveLike: any, token: string) {
	const catalogItemsCopy: ICatalogItemsState = JSON.parse(JSON.stringify(catalogItems))
	setHaveLike(true)
	try {
		const likeFetch = await catalogAPI.addLikeAPI(userId, productId, token)
		if (likeFetch.response.status === 200) {
			const findCatalogItem = catalogItemsCopy.data.findIndex((item: any) => item.id === productId)
			if (findCatalogItem !== -1) {
				const objectToPush = {
					favorite_id: likeFetch.data.id,
					user_id: userId,
				}
				catalogItemsCopy.data[findCatalogItem].favorites.push(objectToPush)
			}
			setHaveLike(true)
			dispatch({
				type: CatalogTypes.GET_CATALOG,
				payload: catalogItemsCopy
			})
			return dispatch({
				type: CatalogTypes.ADD_LIKE_TO_PRODUCT,
				payload: likeFetch.data
			})
		} else {
			return setHaveLike(false)
		}
	} catch (err) {
		setHaveLike(false)
		console.log(err);
	}
}

export async function removeLikeToProductAction(dispatch: any, catalogItems: ICatalogItemsState, productId: string, favorites: IFavorite[], userId: string, setHaveLike: any, token: string) {
	const catalogItemsCopy: ICatalogItemsState = JSON.parse(JSON.stringify(catalogItems))
	for (const favorite of favorites) {
		if (favorite.user_id === userId) {
			setHaveLike(false)
			try {
				const likeFetch = await catalogAPI.removeLikeAPI(favorite.favorite_id, token)
				if (likeFetch.response.status === 200) {
					const findCatalogItem = catalogItemsCopy.data?.findIndex((item: any) => item.id === productId)
					if (findCatalogItem !== -1) {
						catalogItemsCopy.data[findCatalogItem].favorites.forEach((item: any, index: number) => {
							if (item.favorite_id === favorite.favorite_id) {
								catalogItemsCopy.data[findCatalogItem].favorites.splice(index, 1)
							}
						})
						dispatch({
							type: CatalogTypes.GET_CATALOG,
							payload: catalogItemsCopy
						})
					}
					return dispatch({
						type: CatalogTypes.REMOVE_LIKE_TO_PRODUCT,
						payload: favorite.user_id
					})
				} else {
					return setHaveLike(true)
				}
			} catch (err) {
				setHaveLike(true)
				console.log(err);
			}
		}
	}
}

export async function addLikeToRelatedProductAction(dispatch: any, catalogState: ICatalogState, userId: string, productId: string, setHaveLike: any, token: string) {
	const catalogStateCopy: ICatalogState = JSON.parse(JSON.stringify(catalogState))
	setHaveLike(true)
	const likeFetch = await catalogAPI.addLikeAPI(userId, productId, token)
	if (likeFetch.response.status === 200) {
		const objectToPush = {
			favorite_id: likeFetch.data.id,
			user_id: userId,
		}
		const findProduct = catalogStateCopy.relatedProducts.data.findIndex((item: any) => item.id === productId)
		catalogStateCopy.relatedProducts.data[findProduct].favorites.push(objectToPush)
		dispatch({
			type: CatalogTypes.GET_CATALOG,
			payload: catalogStateCopy.catalogItems
		})
		setHaveLike(true)
		return dispatch({
			type: CatalogTypes.SET_RELATED_PRODUCTS,
			payload: catalogStateCopy.relatedProducts
		})
	} else {
		setHaveLike(false)
	}
}

export async function removeLikeToRelatedProductAction(dispatch: any, catalogState: ICatalogState, productId: string, favorites: any, userId: string, setHaveLike: any, token: string) {
	const catalogStateCopy: ICatalogState = JSON.parse(JSON.stringify(catalogState))
	favorites?.forEach(async (favorite: any) => {
		if (favorite.user_id === userId) {
			setHaveLike(false)
			const likeFetch = await catalogAPI.removeLikeAPI(favorite.favorite_id, token)
			if (likeFetch.response.status === 200) {
				const findCatalogItem = catalogStateCopy.catalogItems.data?.findIndex((item: any) => item.id === productId)
				catalogStateCopy.catalogItems.data[findCatalogItem].favorites.forEach((item: any) => {
					if (item.favorite_id === favorite.favorite_id) {
						catalogStateCopy.catalogItems.data[findCatalogItem].favorites.splice(item, 1)
					}
				});
				dispatch({
					type: CatalogTypes.GET_CATALOG,
					payload: catalogStateCopy.catalogItems
				})
				return dispatch({
					type: CatalogTypes.SET_RELATED_PRODUCTS,
					payload: catalogStateCopy.relatedProducts
				})
			} else {
				return setHaveLike(true)
			}
		}
	});
}

export async function setProductBrandIdAction(dispatch: any, brandId: string) {
	return dispatch({ type: CatalogTypes.SET_PRODUCT_BRAND_ID, payload: brandId })
}

export async function addToQuerySearchArrayAction(dispatch: any, value: TQuerySearchArray) {
	return dispatch({ type: CatalogTypes.ADD_TO_QUERY_SEARCH_ARRAY, payload: value })
}

export async function removeFromQuerySearchArrayAction(dispatch: any, value: TQuerySearchArray) {
	return dispatch({ type: CatalogTypes.REMOVE_FROM_QUERY_SEARCH_ARRAY, payload: value })
}

///////////////////////////////////
///////////////////////////////////

async function getAndOrderImagesCatalog(dispatch: any, body: IBodyProductSearch, signal: AbortSignal | undefined, token: string) {
	const catalogFetch = await catalogAPI.getCatalog(body, token, signal)
	dispatch({ type: CatalogTypes.SET_IS_EMPTY_PRODUCTS, payload: false })
	if (catalogFetch.response.status === 404 || catalogFetch.data.data.length === 0) {
		dispatch({ type: CatalogTypes.SET_IS_EMPTY_PRODUCTS, payload: true })
		dispatch({ type: CatalogTypes.RESET_CATALOG_ITEMS })
	}
	if (catalogFetch.data.data?.length < showMoreProducts) {
		dispatch({ type: CatalogTypes.SET_THERE_ARE_MORE_PRODUCTS, payload: false })
	} else {
		dispatch({ type: CatalogTypes.SET_THERE_ARE_MORE_PRODUCTS, payload: true })
	}
	const products = catalogFetch.data.data as ICatalogFetchDataResponse[]
	// Copilot ////
	type IImageWithNumber = {
		original: string;
		number: number;
	}

	await Promise.all(products.map(async (item) => {
		if (item?.images && item.images.length > 0) {
			let imagesWithNumbers: IImageWithNumber[] = [];
			let imagesWithoutNumbers: string[] = [];
			item.images.forEach((image) => {
				const number = parseInt(getExtension(image).split('.')[0], 10);
				if (!isNaN(number)) {
					imagesWithNumbers.push({ original: image, number });
				} else {
					imagesWithoutNumbers.push(image);
				}
			});
			imagesWithNumbers.sort((a, b) => a.number - b.number);
			const indexEndsWithOne = imagesWithNumbers.findIndex((image) => image.number % 10 === 1);
			if (indexEndsWithOne > -1) {
				const [imageEndsWithOne] = imagesWithNumbers.splice(indexEndsWithOne, 1);
				imagesWithNumbers.unshift(imageEndsWithOne);
			}
			const uniqueNumericImages = Array.from(new Set(imagesWithNumbers.map((image) => image.original)));
			item.images = uniqueNumericImages.concat(imagesWithoutNumbers);
		}
	}));
	///////////////
	// products.forEach(async (item) => {
	// 	if (item?.images && item?.images?.length > 0) {
	// 		item.images.reverse()
	// 		item.images.forEach((element, index) => {
	// 			const ext = getExtension(element)
	// 			const getImageNumber = ext.split('.')[0]
	// 			if (getImageNumber[1] == 1 && Number(getImageNumber[0]) !== 1) {
	// 				item.images.slice(index, 1)
	// 				item.images.unshift(element)
	// 			}
	// 			const result = item.images.reduce((acc: string[], item) => {
	// 				if (!acc.includes(item)) {
	// 					acc.push(item);
	// 				}
	// 				return acc;
	// 			}, [])
	// 			item.images = result
	// 		});
	// 	}
	// });
	return {
		count: catalogFetch.data.count,
		data: products,
	} as ICatalogItemsState
}

function getExtension(url: any) {
	return (url = url.substr(1 + url.lastIndexOf("/")).split('?')[0]).split('#')[0].substr(url.lastIndexOf(".") - 2)
}