import { IInitialCatalogDownloadState } from 'interfaces/catalogDownload.interface';
import * as CatalogDownloadType from './catalogDownload.types'

export default function catalogDownloadReducer(state: IInitialCatalogDownloadState, action: any) {
	const { type, payload } = action;

	switch (type) {
		case CatalogDownloadType.SET_FILTERS_SELECTED:
			return {
				...state,
				filtersSelected: payload
			}

		case CatalogDownloadType.SET_IS_LOADING_CATALOG:
			return {
				...state,
				isLoadingCatalog: payload
			}

		case CatalogDownloadType.SET_FILTERED_LIST:
			return {
				...state,
				filteredList: payload
			}

		case CatalogDownloadType.SET_SELECTED_VALUES:
			return {
				...state,
				selectedValues: payload
			}

		case CatalogDownloadType.SET_IS_LOADING_FILTERS:
			return {
				...state,
				isLoadingFilters: payload
			}

		case CatalogDownloadType.SET_FILTERS:
			return {
				...state,
				filters: payload
			}

		case CatalogDownloadType.SET_PVI_SLIDER:
			return {
				...state,
				catalogBodyFetch: {
					...state.catalogBodyFetch,
					filter: {
						...state.catalogBodyFetch.filter,
						pvi: payload
					}
				}
			}

		case CatalogDownloadType.SET_PVPR_SLIDER:
			return {
				...state,
				catalogBodyFetch: {
					...state.catalogBodyFetch,
					filter: {
						...state.catalogBodyFetch.filter,
						pvpr: payload
					}
				}
			}


		case CatalogDownloadType.SET_FILTERS_ALERT:
			return {
				...state,
				filtersAlert: payload
			}

		case CatalogDownloadType.SET_FILTERS_TAGS:
			return {
				...state,
				filtersSelectedTags: payload
			}

		case CatalogDownloadType.SET_IS_OPEN_FILTERS:
			return {
				...state,
				isOpenFilters: payload
			}

		case CatalogDownloadType.SET_FILTERS_SELECTED_TEMP:
			return {
				...state,
				filtersSelectedTemp: payload
			}

		case CatalogDownloadType.SET_COLUMNS_ORDER:
			return {
				...state,
				columnsOrder: payload
			}

		case CatalogDownloadType.SET_DOWNLOADING_STATUS:
			return {
				...state,
				downloadingStatus: {
					isDownloading: payload.isDownloading ?? state.downloadingStatus.isDownloading,
					totalImagesToDownload: payload.totalImagesToDownload ?? state.downloadingStatus.totalImagesToDownload,
					completedDownloads: payload.completedDownloads ?? state.downloadingStatus.completedDownloads,
				}
			}

		case CatalogDownloadType.SET_IS_DOWNLOADING_ZIP:
			return {
				...state,
				isDownloadingZip: payload
			}

		case CatalogDownloadType.SET_DOWNLOAD_IMAGES:
			return {
				...state,
				downloadImages: payload
			}

		case CatalogDownloadType.SET_XHRS_REF:
			return {
				...state,
				xhrsRef: payload
			}

		case CatalogDownloadType.SET_QUERY_SEARCH:
			return {
				...state,
				querySearch: payload
			}

		case CatalogDownloadType.SET_IMAGE_SELECTION:
			return {
				...state,
				imageSelection: payload
			}

		case CatalogDownloadType.SET_FAVORITES:
			return {
				...state,
				catalogBodyFetch: {
					...state.catalogBodyFetch,
					options: {
						...state.catalogBodyFetch.options,
						favorite: payload
					}
				}
			}

		default:
			return state
	}
}