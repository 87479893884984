import { basePath } from "./utils/config"

export const getBrandByIdAPI = async (id: string, token: string): Promise<any> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			filter: { id: id },
			select: "",
			limit: 1000,
			index: 0
		}),
	}
	const response = await fetch(`${basePath}/crud_brand/findManyCrud_brand`, params)
	const data = await response.json()
	return { response, data }
}

export const createNewBrandAPI = async (brand: any, clientId: string, clientName: string, token: string): Promise<any> => {
	const params = {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(brand),
	}
	const response = await fetch(`${basePath}/client_profile/request_brand?client_name=${clientName}&client_id=${clientId}`, params)
	const data = await response.json()
	return { response, data }
}